import React, { createContext, useEffect, useState } from "react";
import { Interval, DateTime } from 'luxon';
import { SessionDataType } from "@/types/interfaces";
import { ACCESS_EVENT } from "@/constants/dates";

const checkDateIsEvent = () => {
    const currentDate = new Date();
    const eventDate = new Date(ACCESS_EVENT);
    return currentDate.toDateString() === eventDate.toDateString();
};

type Props = {
    children: React.ReactNode
}

type ScheduleContextType = {
    onCampusProgram: SessionDataType[],
    onlineProgram: SessionDataType[],
}

const ScheduleContext = createContext<ScheduleContextType>({
    onCampusProgram: [],
    onlineProgram: [],
})

const ScheduleProvider: React.FC<Props> = ({ children }) => {
    const [onCampusProgram, setOnCampusProgram] = useState<SessionDataType[]>([]);
    const [onlineProgram, setOnlineProgram] = useState<SessionDataType[]>([]);

    useEffect(() => {
        const loadProgram = async () => {
            const { PROGRAM } = await import('@/constants/programSchedule');
            if (PROGRAM) {
                setOnCampusProgram(PROGRAM.onCampus);
                setOnlineProgram(PROGRAM.online);
            }
        };

        loadProgram();
    }, []);

    useEffect(() => {
        if (!onlineProgram) return;
        const checkSchedule = () => {
            const currentTime = DateTime.local(); // Current time in the user's local timezone

            setOnlineProgram(prev => {
                return prev.map(session => {
                    const sessionStartTime = DateTime.fromFormat(session.startTime, 'HH:mm', { zone: 'Europe/Moscow' });
                    const sessionEndTime = DateTime.fromFormat(session.endTime, 'HH:mm', { zone: 'Europe/Moscow' });
                    const sessionInterval = Interval.fromDateTimes(sessionStartTime, sessionEndTime);

                    if (currentTime > sessionEndTime) {
                        // The current time is past the session end time
                        return {
                            ...session,
                            streamingStatus: "finished"
                        }

                    } else if (sessionInterval.contains(currentTime)) {
                        // The current time is within the session interval
                        return {
                            ...session,
                            streamingStatus: "active"
                        }
                    } else {
                        // the session not started yet
                        return session;
                    }
                })
            })
        };

        const activate = checkDateIsEvent();

        if (!activate) return;

        // run once when first time opens
        checkSchedule();

        // Run the function every minute 60000
        const interval = setInterval(checkSchedule, 60000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <ScheduleContext.Provider value={{ onCampusProgram, onlineProgram }}>
            {children}
        </ScheduleContext.Provider>
    )
}

export { ScheduleContext, ScheduleProvider }
