import React from "react";
import { useFormik } from "formik";
import { messageRefundSchema } from "@/Schema/messageRefundSchema";
import Textarea from "@/components/Textarea";
import Button from "@/components/Button";
import { Tooltip } from "@/components/Tooltip";
import TitlePageAdmin from "@/components/TitlePageAdmin";
import { toastNotifications } from "@/components/Toastify";
import { ReactComponent as IconWarnMessage } from "./assets/warn-icon.svg";
import { hasError } from "@/utils/formik";
import { useUser } from "@/context/UserContext";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { refundRequest } from "@/api";
import { ProfilePaymentInfo } from "@/pages/Profile/ProfilePaymentInfo";
import { useCheckPaymentStatus } from "@/hooks/useCheckPaymentStatus";
import { Helmet } from "react-helmet";
import Typography from "@/components/Typography";
import "./index.scss";

export const Refund: React.FC = () => {
    const { userInfo } = useUser();

    const { data: isNotPayedComplete } = useCheckPaymentStatus();

    const formik = useFormik({
        initialValues: {
            userMessageRefund: "",
        },
        validationSchema: messageRefundSchema,
        onSubmit: async (formValues, actions) => {
            try {
                await refundRequest(formValues.userMessageRefund);
                toastNotifications.success("Ваша заявка отправлена");
                actions.resetForm();
            } catch (error: any) {
                console.error("[Refund]", error.message)
                toastNotifications.error("Ошибка отправки, попробуйте ещё раз");
            }
        },
    });

    const disallowRefund = isNotPayedComplete;

    return (
        <div className="Refund">
            <Helmet>
                <title>Возврат денег | СберУниверситет</title>
                <meta name="description" content="Возврат денег" />
            </Helmet>
            <TitlePageAdmin textDesktop="Возврат денег" />
            <ProfilePaymentInfo />
            <div className="Refund__Content">
                <div className="Refund__MessageWarn">
                    <div className="Refund__MessageIconWrap">
                        <IconWarnMessage />
                    </div>
                    <div className="Refund__Message">
                        <Typography component="span" size="xs" weight="semiBold" color="dark">
                            {disallowRefund ? (
                                <>
                                    У вас нет произведенных оплат
                                </>
                            ) : (
                                <>
                                    Как только менеджер обработает заявку ― мы отправим ответ на вашу почту:{" "}
                                    <b>{userInfo?.email}</b>
                                </>
                            )}
                        </Typography>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit}>
                    <fieldset className="Refund__FormWrap" disabled={formik.isSubmitting}>
                        <FormikErrorWrap formik={formik} name="userMessageRefund">
                            <Textarea
                                id="refund-message"
                                name="userMessageRefund"
                                label="Причина возврата"
                                placeholder="Минимум 100 символов"
                                minLength={100}
                                error={hasError(formik, "userMessageRefund")}
                                disabled={disallowRefund}
                                messageError={formik.errors.userMessageRefund}
                                length={formik.values.userMessageRefund.length}
                                value={formik.values.userMessageRefund.replace(/\s+/g, " ")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </FormikErrorWrap>

                        <Tooltip
                            className="Refund__Tooltip"
                            tooltip={disallowRefund ? "У вас нет произведенных оплат" : "Необходимо описать причину"}
                            disabled={!disallowRefund && (formik.isValid && formik.dirty)}
                        >
                            <Button
                                className="Refund__Button"
                                tag="button"
                                BtnType="submit"
                                gradient
                                type="colored"
                                size="lg"
                                TextSize="sm"
                                TextWeight="semiBold"
                                text="Отправить"
                                disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting || disallowRefund}
                            />
                        </Tooltip>
                    </fieldset>
                </form>
            </div>
        </div>
    );
};
