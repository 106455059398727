import React from "react";
import { Navigate } from "react-router-dom";
import { useUser } from "@/context/UserContext";
import routes from "@/routes";

export const RequireOfflineFormat: React.FC = () => {
    const { userInfo } = useUser();
    if (userInfo?.info.format === "offline") {
        return <Navigate to={routes.profileTariffs} />;
    }
    return null;
};
