import React from "react";
import Container from "components/Container";
import Headline from "components/Headline";
import Typography from "components/Typography";
import BookIcon from "./assets/book.png";
import HatIcon from "./assets/hat.png";
import BLocksIcon from "./assets/blocks.png";
import GlowBubbleBg from "./GlowBubbleBg";
import { ABOUT_CONTEST_HASH_ID } from "@/constants";
import "./index.scss";

type ItemProps = {
    text: string;
    icon: string;
}

const Item: React.FC<ItemProps> = ({ text, icon }) => {
    return (
        <div className="CompetitionFocus__ItemWrapper">
            <div className="CompetitionFocus__ItemBox">
                <img className="CompetitionFocus__ItemBoxIcon" src={icon} alt="sber icon"/>
            </div>
            <Typography className="CompetitionFocus__ItemBoxText" color="light" size="lg" weight="regular" isCentered>
                {text}
            </Typography>
        </div>
    );
};

const TEXT = [
    {
        text: 'Быстро и удобно\nсоздать и провести\nобучение',
        icon: HatIcon,
    },
    {
        text: 'Замотивировать\nк учёбе',
        icon: BookIcon,
    },
    {
        text: 'Персонализировать\nобразовательный\nопыт',
        icon: BLocksIcon,
    },
]

const CompetitionFocus: React.FC = () => {
    const renderElements = TEXT.map((el, index) => (
        <Item key={index} text={el.text} icon={el.icon} />
    ))

    return (
        <Container className="CompetitionFocus">
            <GlowBubbleBg className="CompetitionFocus__GlowBubble"/>
            <div className="CompetitionFocus__Inner" id={ABOUT_CONTEST_HASH_ID}>
                <Headline className="CompetitionFocus__Title" level="h2" size="xlg" theme="light" isCentered>
                    Мы хотим найти решения,
                    <br /> которые поддержат
                    <br /> гармоничное развитие
                    <br /> человека в&nbsp;эпоху технологий
                </Headline>
            </div>
            <div className="CompetitionFocus__Items">
                {renderElements}
            </div>
        </Container>
    );
};

export default CompetitionFocus;
