import React from "react";
import classNames from "classnames";
import {ReactComponent as DotIcon } from './assets/dot.svg';
import Typography from "@/components/Typography";
import "./index.scss";

type Props = {
    className?: string;
    Text: string;
    hasDot?: boolean;
};

const Label: React.FC<Props> = ({ className, Text, hasDot = false }) => {
    return (
        <div className={classNames(className, "Label")}>
            {hasDot && <DotIcon />}
            <Typography className="Label__Text" size="sm" weight="semiBold" color="light">
                {Text}
            </Typography>
        </div>
    );
};

export default Label;
