import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import Headline from "@/components/Headline";
import { ReactComponent as SberIconLigh } from "./assets/sber-light.svg";
import { ReactComponent as SberIconColored } from "./assets/sber-colored.svg";
import PaidPlanForm from "@/components/PaidPlanForm";
import PlanPriceTag from "@/components/PlanPriceTag";
import Button from "@/components/Button";
import { Tooltip } from "@/components/Tooltip";
import { ReactComponent as CurrentPlanIcon } from "./assets/current-plan.svg";
import { ReactComponent as CheckIcon } from "./assets/check-icon.svg";
import routes from "@/routes";
import { useNavigate } from "react-router-dom";
import "./index.scss";

type featuresProps = {
    text: string;
};

type Props = {
    theme: "light" | "dark";
    header: string;
    features: featuresProps[];
    currentPlan: boolean;
    initValue?: boolean;
    free?: boolean;
    vip?: boolean;
    isDisabled: boolean;
    tooltipText: string;
};

const TariffContainer: React.FC<PropsWithChildren<Props>> = ({
    theme = "light",
    free = false,
    header,
    features,
    currentPlan = false,
    vip = false,
    initValue = false,
    isDisabled,
    tooltipText,
    children,
}) => {
    const navigate = useNavigate();

    const renderElement = features?.map((el, index) => {
        return (
            <li key={index} className="TariffContainer__ListItem">
                <SberIconColored className="TariffContainer__ListIcon" />
                {el.text}
            </li>
        );
    });

    return (
        <div
            className={classNames("TariffContainer", {
                "TariffContainer--dark": theme === "dark",
                "TariffContainer--CurrentPlan": currentPlan,
            })}
        >
            <Headline
                className="TariffContainer__Title"
                theme={theme === "light" ? "dark" : "light"}
                level="h3"
            >
                {header}
            </Headline>

            {currentPlan && (
                <div
                    className={classNames("TariffContainer__CurrentPlan", {
                        "TariffContainer__CurrentPlan--vip": vip,
                    })}
                >
                    <CurrentPlanIcon />
                    <p>
                        {vip
                            ? "Сейчас вы зарегистрированы как VIP-гость."
                            : "Сейчас вы зарегистрированы на данный формат участия."}
                    </p>
                </div>
            )}

            <ul className="TariffContainer__List">{renderElement}</ul>

            {children}

            {currentPlan ? (
                <div className="TariffContainer__YourPlanWrapper">
                    <div
                        className={classNames({
                            TariffContainer__YourPlan: true,
                            "TariffContainer__YourPlan--dark": theme === "dark",
                        })}
                    >
                        <CheckIcon />
                        <p>Это ваш текущий тариф</p>
                    </div>
                </div>
            ) : free ? (
                <>
                    <PlanPriceTag
                        className="TariffContainer__FreePlan"
                        showTag={false}
                        withDiscount="100"
                        color="black"
                    />
                    <Tooltip
                        className="TariffContainer__FreeTooltip"
                        tooltip={tooltipText}
                        disabled={!isDisabled}
                    >
                        <Button
                            className={classNames("TariffContainer__FreeBtn", {
                                "TariffContainer__FreeBtn--disabled":
                                    isDisabled,
                            })}
                            tag="button"
                            BtnType="submit"
                            text="Выбрать"
                            size="lg"
                            type="gradiant-borders"
                            onClick={() => navigate(routes.onlineSignup)}
                            isWide
                            disabled={isDisabled}
                        />
                    </Tooltip>
                </>
            ) : (
                <PaidPlanForm
                    className="TariffContainer__PaidPlan"
                    buttonText="Выбрать"
                    initValue={initValue}
                    disabled={isDisabled}
                    tooltipText={tooltipText}
                    showNotes
                    navigateToForms
                />
            )}
        </div>
    );
};

export default TariffContainer;
