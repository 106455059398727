import React from "react";
import CupIcon from "../assets/ic-cup.png";
import FlagIcon from "../assets/ic-flag.png";
import PetardIcon from "../assets/ic-petard.png";
import "./index.scss";

type Icons = "start" | "finish" | "break";

type Props = {
    type: Icons;
}

const ICONS: Record<Icons, string> = {
    start: PetardIcon,
    finish: FlagIcon,
    break: CupIcon,
}

const ProgramIcons: React.FC<Props> = ({ type }) => {
    return (
        <div className="ProgramIcons">
            <img className="ProgramIcons__Img" src={ICONS[type]} alt={type} />
        </div>
    );
}

export default ProgramIcons;
