import React from "react";
import { ReactComponent as StubIcon } from './assets/stubIcon.svg';
import TitlePageAdmin from "@/components/TitlePageAdmin";
import Typography from "@/components/Typography";
import "./index.scss";

export const Certificates: React.FC = () => {
    return (
        <div className="Certificates">
            <TitlePageAdmin textDesktop="Сертификат участника" />
            <div className="Certificates__Content">
                <StubIcon />
                <Typography className="Certificates__Text" component="span" size="sm" weight="semiBold" color="dark">
                    Доступ к сертификату<br />откроется 25 октября
                </Typography>
            </div>
        </div>
    );
};
