import React, { useMemo } from "react";
import Button from "@/components/Button";
import { Tooltip } from "@/components/Tooltip";
import { hasError } from "@/utils/formik";
import { FormikData } from "@/types/formik";
import Radio from "@/components/Radio";
import Typography from "@/components/Typography";
import "./index.scss";

const AccommodationChoice = ({
    formik,
    nextStep,
}: {
    formik: FormikData<any>;
    nextStep: () => void;
}) => {
    const withAccommodation =
        formik.values.is_planning_to_stay_at_the_hotel === true;
    const withNoAccommodation =
        formik.values.is_planning_to_stay_at_the_hotel === false;

    const isValid = useMemo(() => {
        return !hasError(formik, "is_planning_to_stay_at_the_hotel", false);
    }, [formik]);

    return (
        <>
            <Typography
                className="ActivityInformation__text"
                size="sm"
                weight="semiBold"
                color="dark"
            >
                Выберите один из вариантов
            </Typography>
            <div className="AccommodationChoice__OptionsWrapper">
                <Radio
                    className="AccommodationChoice__Option"
                    id="accommodation_yes"
                    name="is_planning_to_stay_at_the_hotel"
                    text="Да"
                    value="is_planning_to_stay_at_the_hotel"
                    checked={withAccommodation}
                    onChange={() =>
                        formik.setFieldValue(
                            "is_planning_to_stay_at_the_hotel",
                            true,
                        )
                    }
                    onBlur={formik.handleBlur}
                />
                <Radio
                    className="AccommodationChoice__Option"
                    id="accommodation_no"
                    name="is_planning_to_stay_at_the_hotel"
                    text="Нет"
                    value="is_planning_to_stay_at_the_hotel"
                    checked={withNoAccommodation}
                    onChange={() =>
                        formik.setFieldValue(
                            "is_planning_to_stay_at_the_hotel",
                            false,
                        )
                    }
                    onBlur={formik.handleBlur}
                />
            </div>

            <div className="AccommodationChoice__Footer">
                {withAccommodation && (
                    <p className="AccommodationChoice__Note">
                        {
                            "Мы свяжемся с вами по вопросу\nбронирования номера"
                        }
                    </p>
                )}

                <Tooltip
                    className="AccommodationChoice__Tooltip"
                    tooltip={"Необходимо выбрать вариант проживания"}
                    disabled={isValid}
                >
                    <Button
                        tag="button"
                        onClick={nextStep}
                        text="Далее"
                        size="lg"
                        type="colored"
                        isWide
                        gradient
                        disabled={!isValid}
                    />
                </Tooltip>
            </div>
        </>
    );
};

export default AccommodationChoice;
