import ReactDOM from "react-dom";
import React, { useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { ReactComponent as CloseIcon } from "./assets/close-btn.svg";
import { ReactComponent as CloseIconMobile } from "./assets/close-btn-mobile.svg";
import { ReactComponent as CloseIconProgram } from "./assets/close-btn-program.svg";
import { motion, AnimatePresence } from "framer-motion";
import classNames from "classnames";
import useMedia from "@/hooks/useMedia";
import "./index.scss";

type Props = {
    isPopupActive: boolean;
    closePopup: () => void;
    children: React.ReactNode;
    className?: string;
    position?: "bottom" | "right" | "center";
    withBodyScroll?: boolean;
    withCloseBtn?: boolean;
    isSubPopup?: boolean;
};

const Popup: React.FC<Props> = ({
    isPopupActive,
    closePopup,
    children,
    className,
    position = "bottom",
    withBodyScroll = false,
    isSubPopup = false,
    withCloseBtn = true,
}) => {
    const location = useLocation();
    const { isDesktop } = useMedia();
    const popupLayout = useRef<HTMLDivElement>(null);
    const popupContent = useRef<HTMLDivElement>(null);
    const animationSpeed = 0.5;

    useEffect(() => {
        let bodyScroll: any;
        if (withBodyScroll || isSubPopup) return;

        bodyScroll = setTimeout(() => {
            document.body.style.overflow = isPopupActive ? "hidden" : "unset";
        }, 300);

        return () => {
            if (withBodyScroll) return;
            window.clearTimeout(bodyScroll);
        };
    }, [isPopupActive, withBodyScroll, isSubPopup]);

    useEffect(() => {
        if (!isPopupActive) return;
        closePopup();
    }, [location]);

    const handleClose = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        closePopup();
    };

    const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
        e.stopPropagation();
        if (e.target === popupLayout.current) {
            closePopup();
        }
    };

    return ReactDOM.createPortal(
        <AnimatePresence>
            {isPopupActive && (
                <motion.div
                    ref={popupLayout}
                    className={classNames(className, {
                        Popup: true,
                        "Popup--right": position === "right",
                        "Popup--center": position === "center",
                    })}
                    onClick={handleClick}
                    initial={{ backgroundColor: "rgba(8, 8, 8, 0.0)" }}
                    animate={{ backgroundColor: "rgba(8, 8, 8, 0.8)" }}
                    exit={{ backgroundColor: "rgba(8, 8, 8, 0.0)" }}
                    transition={{ duration: animationSpeed, ease: "easeInOut" }}
                >
                    <motion.div
                        ref={popupContent}
                        className="Popup__Box"
                        initial={
                            position === "right"
                                ? { translateX: "100%" }
                                : position === "center"
                                    ? { translateY: "100vh" }
                                    : { translateY: "100%" }
                        }
                        animate={
                            position === "right"
                                ? { translateX: "0%" }
                                : position === "center"
                                    ? { translateY: "0vh" }
                                    : { translateY: "0%" }
                        }
                        exit={
                            position === "right"
                                ? { translateX: "100%" }
                                : position === "center"
                                    ? { translateY: "100vh" }
                                    : { translateY: "100%" }
                        }
                        transition={{
                            duration: animationSpeed,
                            ease: "easeInOut",
                        }}
                    >
                        {withCloseBtn && (
                            <button
                                className="Popup__CloseBtn"
                                onClick={handleClose}
                            >
                                {position === "right" ? (
                                    <CloseIconProgram />
                                ) : (
                                    isDesktop ? <CloseIcon /> : <CloseIconMobile />
                                )}
                            </button>
                        )}

                        {children}
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>,
        document.getElementById("popupPortal") as HTMLElement,
    );
};

export default Popup;
