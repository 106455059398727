import React from "react";
import ParticipantsList from "@/components/ParticipantsList";
import Headline from "@/components/Headline";
import { useSpeakers } from "@/hooks/useParticipants";
import { SPEAKERS_HASH_ID } from "@/constants";
import "./index.scss";

const ConferenceSpeakers: React.FC = () => {
    const queryResults = useSpeakers();

    return (
        <section className="ConferenceSpeakers" id={SPEAKERS_HASH_ID}>
            <div className="ConferenceSpeakers__HeaderWrapper">
                <Headline
                    className="ConferenceSpeakers__Header"
                    level="h2"
                    size="xlg"
                    theme="light"
                    isCentered
                >
                    Спикеры
                </Headline>
            </div>
            <ParticipantsList
                className="ConferenceSpeakers__List"
                swiperId="ConferenceSpeakers"
                slidesPerView={1.3}
                {...queryResults}
            />
        </section>
    );
};

export default ConferenceSpeakers;
