import React from "react";
import Button from "components/Button";
import Headline from "components/Headline";
import classNames from "classnames";
// import { ReactComponent as DownloadIcon } from "./assets/download-icon.svg";
import "./index.scss";

type Props = {
    toggleProgram: (program: "online" | "onCampus") => void;
    programFormat: "online" | "onCampus";
};

const ProgramHeading: React.FC<Props> = ({ toggleProgram, programFormat }) => {
    const isOnline = programFormat === "online";
    const isOnCampus = programFormat === "onCampus";

    return (
        <div className="ProgramHeading">
            <div className="ProgramHeading__Btns">
                <Button
                    className={classNames("ProgramHeading__Btn", {
                        "ProgramHeading__Btn--not-active": !isOnCampus,
                    })}
                    tag="button"
                    text="Очно"
                    BtnType="button"
                    onClick={() => toggleProgram("onCampus")}
                    gradient
                />
                <Button
                    className={classNames("ProgramHeading__Btn", {
                        "ProgramHeading__Btn--not-active": !isOnline,
                    })}
                    tag="button"
                    text="Онлайн"
                    BtnType="button"
                    onClick={() => toggleProgram("online")}
                    gradient
                />
            </div>
            <Headline level="h2" size="xlg" theme="light" isCentered>
                Программа конференции
            </Headline>
            {/* <a
                className="ProgramHeading__Download"
                href=""
                >
                Скачать PDF
                <DownloadIcon />
            </a> */}
        </div>
    );
};

export default ProgramHeading;
