import React from "react";
import { SUPPORT_EMAIL, SUPPORT_TELEGRAM } from "@/constants/common";
import { ReactComponent as QuestionIcon } from "./assets/question-icon.svg";

export const EducationWarn: React.FC = () => {
    return (
        <div className="FromEducation__Warning">
            <QuestionIcon className="FromEducation__WarningIcon"/>
            <div className="FromEducation__WarningContent">
                <p className="FromEducation__Warning-text">{"Для получения промокода свяжитесь\nс\u00A0поддержкой "}</p>
                <span className="FromEducation__Warning-links-wrapper">
                    <a className="FromEducation__Warning-link" href={`mailto:${SUPPORT_EMAIL}`}>
                        {SUPPORT_EMAIL}
                    </a>
                    Или
                    <a className="FromEducation__Warning-link" href={SUPPORT_TELEGRAM} target="_blank" rel="noreferrer">
                        Telegram
                    </a>
                </span>
            </div>
        </div>
    );
};
