import React from 'react';
import Button from '@/components/Button';
import { ReactComponent as CloseBtn } from '../assets/close-btn.svg';
import { ReactComponent as SuccessPaymentIcon } from '../assets/success-payment-icon.svg';
import './index.scss';

const SberToast: React.FC = () => {

    return (
        <>
            <div className='SberToast'>
                <div className='SberToast__IconContainer'>
                    <SuccessPaymentIcon />
                </div>
                <h2 className='SberToast__Title'>Ваша заявка принята</h2>

                <p className='SberToast__Text'>
                    {"Как только мы обработаем вашу заявку ―\nответ поступит на вашу электронную почту"}
                </p>

                <Button
                    className="SberToast__Btn"
                    tag="button"
                    text="Хорошо"
                    size='lg'
                    type='colored'
                    isWide
                    gradient
                />
            </div>
            <CloseBtn className='Payment__CloseBtn' />
        </>
    )
}

export default SberToast;