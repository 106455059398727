import Typography from '@/components/Typography';
import Headline from '@/components/Headline';
import './index.scss';

type Props = {
    progress: string;
    date: string;
    stage: string;
}

const StageCard: React.FC<Props> = ({ date, stage, progress }) => {
    return (
        <div className='StageCard'>
            <img className='StageCard__Img' src={progress} alt={stage} />
            <div className='StageCard__Body'>
                <Headline className='StageCard__Title' size='md' level='h3' theme='light'>
                    {date}
                </Headline>
                <Typography size='lg' color='light' opacity={0.6} weight='semiBold'>
                    {stage}
                </Typography>
            </div>
        </div>
    )
}
export default StageCard;