import React, { PropsWithChildren } from "react";
import Headline from "components/Headline";
// import Button from "components/Button";
// import { ReactComponent as LinkIcon } from "./assets/link-icon.svg";
import classNames from "classnames";
import './index.scss';

type Props = {
    className?: string,
    title: string[],
    reference?: string,
    link?: string,
    vertical?: boolean,
    gradientSubtitle?: boolean,
    hasLink? : boolean,
    togglePopup?: () => void,
}

const SessionHeader: React.FC<PropsWithChildren<Props>> = ({
    children,
    className,
    title,
    reference,
    link,
    vertical = false,
    hasLink = false,
    togglePopup = () => {}
}) => {

    const renderListTitle = title?.map((el, index) => {
        return (
            <li
                key={index}
                className="SessionHeader__Title"
            >
                {el}
            </li>
        )
    })

    const handleClick = () => {
        if (!hasLink) return;

        togglePopup();
    }

    return (
        <div className={classNames(className, {
            "SessionHeader": true,
            "SessionHeader--vertical": vertical,
        })}>
            <div className="SessionHeader__Info">
                <div>
                    {/* disabled */}
                    {/* {reference &&
                        <Headline
                            className="SessionHeader__Reference"
                            level="h3"
                            theme="dark"
                        >
                            {reference}
                        </Headline>
                    } */}
                    {
                        title.length > 1 ?
                            <ul className="SessionHeader__Title-list">
                                {renderListTitle}
                            </ul>
                            :
                            <div
                                className={classNames({
                                    "SessionHeader__TitleWrapper": hasLink
                                })}
                                onClick={handleClick}
                            >
                                <Headline
                                    className="SessionHeader__Title"
                                    level="h3"
                                    theme="dark"
                                >
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: title[0],
                                        }}
                                    />
                                </Headline>
                            </div>
                    }
                </div>
                {children}
            </div>
            {/* disabled */}
            {/* { link && (
                <Button
                    className="SessionHeader__Link"
                    text="по записи"
                    iconRight={<LinkIcon />}
                    href={"#consultation"}
                    tag="a"
                    type="transparent"
                />
            )} */}
        </div >
    )
}

export default SessionHeader;