import React, { useMemo } from "react";
import Radio from "@/components/Radio";
import { FormInput } from "@/components/Inputs";
import Button from "@/components/Button";
import { Tooltip } from "@/components/Tooltip";
import { hasError } from "@/utils/formik";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { FormikData } from "@/types/formik";
import Typography from "@/components/Typography";
import "./index.scss";

const TransportInformation = ({
    formik,
    nextStep,
}: {
    formik: FormikData<any>;
    nextStep: () => void;
}) => {
    const isValid = useMemo(() => {
        return !(
            hasError(formik, "transport", false) ||
            hasError(formik, "car_brand", false) ||
            hasError(formik, "car_state_number", false)
        );
    }, [formik]);

    return (
        <>
            <Typography
                className="TransportInformation__text"
                size="sm"
                weight="semiBold"
                color="dark"
            >
                Выберите один из вариантов
            </Typography>
            <div className="TransportInformation__RadioWrapper">
                <Radio
                    className="TransportInformation__Radio"
                    id="car"
                    name="transport"
                    text="Автомобиль"
                    value="car"
                    checked={formik.values.transport === "car"}
                    onChange={formik.handleChange}
                />
                <Radio
                    className="TransportInformation__Radio"
                    id="taxi"
                    name="transport"
                    text="Такси"
                    value="taxi"
                    checked={formik.values.transport === "taxi"}
                    onChange={formik.handleChange}
                />
                <Radio
                    className="TransportInformation__Radio"
                    id="transfer"
                    name="transport"
                    text="Трансфер"
                    value="transfer"
                    checked={formik.values.transport === "transfer"}
                    onChange={formik.handleChange}
                />
                <Radio
                    className="TransportInformation__Radio"
                    id="unknown"
                    name="transport"
                    text="Пока не знаю"
                    value="unknown"
                    checked={formik.values.transport === "unknown"}
                    onChange={formik.handleChange}
                />
            </div>

            <div className="TransportInformation__Fields">
                {formik.values.transport === "car" && (
                    <div className="TransportInformation__inputsGroup">
                        <Typography
                            component="span"
                            size="xs"
                            color="gray"
                            weight="regular"
                        >
                            Марка автомобиля и гос. номер
                        </Typography>
                        <div className="TransportInformation__inputsWrapper">
                            <FormikErrorWrap formik={formik} name="car_brand">
                                <FormInput
                                    className="TransportInformation__Input"
                                    type="text"
                                    id="car_brand"
                                    name="car_brand"
                                    placeholder="Введите марку автомобиля"
                                    value={formik.values.car_brand}
                                    error={hasError(formik, "car_brand")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </FormikErrorWrap>

                            <FormikErrorWrap
                                formik={formik}
                                name="car_state_number"
                            >
                                <FormInput
                                    className="TransportInformation__Input"
                                    type="text"
                                    id="car_state_number"
                                    name="car_state_number"
                                    placeholder="Гос номер автомобиля"
                                    value={formik.values.car_state_number}
                                    error={hasError(formik, "car_state_number")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </FormikErrorWrap>
                        </div>
                    </div>
                )}
            </div>
            <Tooltip
                className="TransportInformation__Tooltip"
                tooltip="Необходимо заполнить все поля"
                disabled={isValid}
            >
                <Button
                    className="TransportInformation__Btn"
                    tag="button"
                    onClick={nextStep}
                    text="Далее"
                    size="lg"
                    type="colored"
                    isWide
                    gradient
                    disabled={!isValid}
                />
            </Tooltip>
        </>
    );
};

export default TransportInformation;
