import React, { PropsWithChildren } from "react";
import "./index.scss";

type Props = {
    title: string;
    icon?: React.ReactNode;
}

const SessionContainer: React.FC<PropsWithChildren<Props>> = ({ children, title, icon }) => {
    return (
        <div className="SessionContainer">
            <div className="SessionContainer__Title">
                {title}
                {icon}
            </div>
            {children}
        </div>
    )
}

export default SessionContainer;