import React from "react";
import Headline from "@/components/Headline";
import Typography from "@/components/Typography";
import { ReactComponent as RokectIcon } from "./assets/rocket-icon.svg";
import { ReactComponent as QuestionIcon } from "./assets/question-icon.svg";
import { ReactComponent as PersonIcon } from "./assets/person-icon.svg";
import { ReactComponent as MedalIcon } from "./assets/medal-icon.svg";
import useMedia from "@/hooks/useMedia";
import "./index.scss";

const TariffHeader: React.FC = () => {
    const { isDesktop } = useMedia();

    const features = [
        {
            icon: <RokectIcon />,
            text: "Участие во всех\nмероприятиях\nконференции",
        },
        {
            icon: <QuestionIcon />,
            text: isDesktop ? "Возможность задать\nсвой вопрос спикеру" : "Возможность\nзадать свой\nвопрос спикеру",
        },
        {
            icon: <PersonIcon />,
            text: isDesktop ? "Общение\nс экспертами рынка" : "Общение\nс экспертами\nрынка",
        },
        {
            icon: <MedalIcon />,
            text: isDesktop ? "Сертификат участника\nв личном кабинете" : "Сертификат\nучастника\nв личном кабинете",
        },
    ];
    const renderFeatures = features?.map((el, index) => {
        return (
                <div key={index} className="TariffHeader__Feature">
                    <span className="TariffHeader__FeatureIcon">
                        {el.icon}
                    </span>
                    <p className="TariffHeader__FeatureText">{el.text}</p>
                </div>
        );
    });

    return (
        <div className="TariffHeader">
            <div className="TariffHeader__Top">
                <Headline
                    className="TariffHeader__Title"
                    theme="light"
                    size="xlg"
                    isCentered
                >
                    {"Выберите формат\nучастия в\u00A0конференции"}
                </Headline>
                <Typography
                    className="TariffHeader__Subtext"
                    size="md"
                    weight="regular"
                    opacity={0.52}
                    color="light"
                    isCentered
                >
                    И очно, и онлайн: вы получите уникальный опыт и знания
                    <br /> от&nbsp;бизнес-лидеров и экспертов в области
                    образования
                </Typography>
            </div>

            <div className="TariffHeader__FeaturesList">
                {renderFeatures}
            </div>
        </div>
    );
};

export default TariffHeader;
