import React from "react";
import ProgramContainer from "../ProgramContainer";
import useCustomScreen from "@/hooks/useCustomScreen";
import { SessionDataType } from "@/types/interfaces";
import { ReactComponent as SliderNavBtn } from "../../assets/arrow-right.svg";
import useMedia from "@/hooks/useMedia";
import { Swiper, SwiperSlide, SwiperProps } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css/navigation";
import "swiper/css";
import "./index.scss";

type Props = {
    data: SessionDataType[];
    swiperId: string;
};

const sliderParams: SwiperProps = {
    spaceBetween: 8,
    slidesOffsetBefore: 8,
    centeredSlides: true,
    centeredSlidesBounds: true,
    modules: [Navigation],
};

const ProgramSlider: React.FC<Props> = ({ data, swiperId }) => {
    const [ isScreenActive ] = useCustomScreen(992, 1200);
    const { isDesktop, isMobile } = useMedia();

    const renderSlides = data.map((el, index) => {
        if (isDesktop && !isScreenActive) {
            return (
                <ProgramContainer
                    key={index}
                    className="ProgramSlider__MultipleSessions"
                    data={el}
                    withLink
                    vertical
                />
            );
        }

        return (
            <SwiperSlide key={index} className="ProgramSlider__Slider">
                <ProgramContainer
                    className="ProgramSlider__MultipleSessions"
                    data={el}
                    withLink
                    vertical
                />
            </SwiperSlide>
        );
    });

    return (
        <div className="ProgramSlider">
            {isDesktop && !isScreenActive ? (
                <div className="ProgramSlider__Wrapper">
                    {renderSlides}
                </div>
            ) : (
                <>
                    <Swiper
                        id={swiperId}
                        slidesPerView={isMobile ? 1.2 : isScreenActive ? 1.8 : 1.4}
                        navigation={{
                            prevEl: `.to-Prev-${swiperId}`,
                            nextEl: `.to-Next-${swiperId}`,
                        }}
                        {...sliderParams}
                    >
                        {renderSlides}
                    </Swiper>

                    <div className="ProgramSlider__SliderBtns">
                        <div
                            className={`ProgramSlider__Slider-Prev to-Prev-${swiperId}`}
                        >
                            <SliderNavBtn />
                        </div>
                        <div
                            className={`ProgramSlider__Slider-Next to-Next-${swiperId}`}
                        >
                            <SliderNavBtn />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default ProgramSlider;
