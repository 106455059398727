import React, { PropsWithChildren } from "react";
import classNames from "classnames";
import { TextSize, TextWeight } from "@/types";
import "./index.scss";

type Props = {
    color?: "gray" | "dark" | "light" | "green" | "red" | "inherit";
    opacity?: number;
    gradient?: "green" | "red";
    size?: TextSize;
    weight?: TextWeight;
    isCentered?: boolean;
    className?: string;
    component?: "p" | "span";
}

const Typography: React.FC<PropsWithChildren<Props>> = ({
    component = "p",
    className,
    color = "light",
    size = "md",
    weight = "regular",
    gradient,
    isCentered = false,
    opacity = 1,
    children,
}) => {
    const Tag = component;
    return (
        <Tag
            className={classNames(className, "Typography", {
                [`Typography--weight-${weight}`]: weight,
                [`Typography--color-${color}`]: color,
                [`Typography--size-${size}`]: size,
                [`Typography--Gradient-${gradient}`]: gradient,
                [`Typography--Centered`]: isCentered,
            })}
            style={{
                opacity,
            }}
        >
            {children}
        </Tag>
    );
};

export default Typography;
