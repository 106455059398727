import React, { useMemo } from "react";
import Radio from "@/components/Radio";
import Button from "@/components/Button";
import { Tooltip } from "@/components/Tooltip";
import PlanPriceTag from "@/components/PlanPriceTag";
import { hasError } from "@/utils/formik";
import { FormikData } from "@/types/formik";
import { GENERAL_PRICE } from "@/constants/tariffs";
import "./index.scss";

const TicketPayment = ({ formik, discount = null }: { formik: FormikData<any>; discount?: boolean | null }) => {
    const isValid = useMemo(() => {
        return !hasError(formik, "legal_type", false);
    }, [formik]);

    return (
        <>
            <div className="TicketPayment__Inputs">
                <div className="TicketPayment__InputWrapper">
                    <Radio
                        className="TicketPayment__Radio"
                        id="individual"
                        name="legal_type"
                        text="Физическое лицо"
                        value="individual"
                        checked={formik.values.legal_type === "individual"}
                        onChange={formik.handleChange}
                    />
                    <p className="TicketPayment__Note">Оплата банковской картой</p>
                </div>
                <div className="TicketPayment__InputWrapper">
                    <Radio
                        className="TicketPayment__Radio"
                        id="legal"
                        name="legal_type"
                        text="Юридическое лицо"
                        value="legal"
                        checked={formik.values.legal_type === "legal"}
                        onChange={formik.handleChange}
                    />
                    <p className="TicketPayment__Note">Нужно оставить заявку на оплату счета/договора</p>
                </div>
            </div>

            <PlanPriceTag className="TicketPayment__Price" price={GENERAL_PRICE} withDiscount={discount} />

            <Tooltip
                className="TicketPayment__Tooltip"
                tooltip={"Необходимо заполнить все поля"}
                disabled={isValid}
            >
                <Button
                    className="TicketPayment__Btn"
                    tag="button"
                    BtnType="submit"
                    text={formik.values.legal_type === "individual" ? "Оплатить" : "Отправить заявку"}
                    size="lg"
                    type="colored"
                    isWide
                    gradient
                    disabled={!isValid}
                    isLoading={formik.isSubmitting}
                />
            </Tooltip>
        </>
    );
};

export default TicketPayment;
