import React, { useEffect } from "react";
import AccessGateway from "@/components/AccessGateway";
import NewPassword from "./NewPassword";
import { useNavigate, useParams } from "react-router-dom";
import { validatePasswordRecoveryToken } from '@/api';
import { toastNotifications } from '@/components/Toastify';
import { useToggle } from "react-use";
import Loader from "@/components/Loader";
import { Helmet } from "react-helmet";
import routes from "@/routes";

const ResetPassword: React.FC = () => {
    const { recovery_token } = useParams();
    const navigate = useNavigate();
    const [valid, toggle] = useToggle(true);

    useEffect(() => {
        const validateToken = async (recovery_token: string | undefined) => {
            if (!recovery_token) return;

            try {
                const params: {
                    recovery_token: string;
                } = {
                    recovery_token,
                }

                const response = await validatePasswordRecoveryToken(params);

                if(response.data.success) {
                    toggle();
                }

            } catch {
                navigate(routes.conference)
                toastNotifications.error("Что-то пошло не так, попробуйте ещё раз");
            }
        }

        validateToken(recovery_token);
    }, [recovery_token])

    return (
        <>
            <Helmet>
            <title>Смена пароля | СберУниверситет</title>
                <meta name="description" content="Смена пароля" />
            </Helmet>
            <AccessGateway
                onCloseNavigateTo={routes.conference}
            >
                {
                    valid && recovery_token?
                    <NewPassword recoveryToken={recovery_token} />
                    :
                    <Loader />
                }
            </AccessGateway>
        </>
    )
}

export default ResetPassword;