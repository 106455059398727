import * as yup from "yup";
import memoize from "lodash/memoize";
import { checkEmail } from "@/api";
import { errors } from "@/constants/messages";
import { yupSequence } from "@/utils/yup";
import {
    ACTIVITY_TYPES,
    CYRILLIC_REQUIREMENT,
    CYRILLIC_SURNAME_REQUIREMENT,
    FORMAT_TYPES,
    LEGAL_TYPES,
    PASSWORD_REQUIREMENT,
    TARIFF_TYPES,
    TARIFF_WITH_REQUIRED_TRANSPORT,
} from "@/constants/validations";

const memoCheckEmail = memoize(checkEmail, (...args) => JSON.stringify(args));

export const basicSchema = {
    format: yup.string().oneOf(FORMAT_TYPES).required(errors.required),
    tariff: yup.string().oneOf(TARIFF_TYPES).required(errors.required),
    name: yup.string().required(errors.required).matches(CYRILLIC_REQUIREMENT, errors.cyrilic),
    surname: yup.string().required(errors.required).matches(CYRILLIC_SURNAME_REQUIREMENT, errors.cyrilic),
    email: yup.string().test(
        yupSequence(
            "email",
            yup.string().required(errors.required),
            yup.string().email(errors.email),
            yup.string().test({
                name: "email-exist",
                message: errors.email_exist,
                test: async (value) => {
                    try {
                        const params = {
                            email: value ?? "",
                        };
                        const response = await memoCheckEmail(params);
                        const { is_exists } = response.data.data;
                        return !is_exists;
                    } catch (err) {
                        console.error(err);
                        return false;
                    }
                },
            }),
        ),
    ),
    agree_processing_of_personal_data: yup.boolean().oneOf([true], errors.required).required(errors.required),
    agree_receive_promotional_and_informational_mailings: yup.boolean().oneOf([true], errors.required).required(errors.required),
};

export const passwordsScheme = {
    password: yup.string().matches(PASSWORD_REQUIREMENT, errors.password).required(errors.required),
    password_confirmation: yup
        .string()
        .oneOf([yup.ref("password")], errors.password_confirmation)
        .required(errors.required),
};

export const activitySchema = {
    activity: yup.array().of(yup.string().oneOf(ACTIVITY_TYPES)).min(1, errors.oneof),

    place_of_study: yup
        .string()
        .when("activity", ([activity], schema) =>
            activity.includes("study") ? schema.required(errors.required) : schema.nullable(),
        ),
    speciality: yup
        .string()
        .when("activity", ([activity], schema) =>
            activity.includes("study") ? schema.required(errors.required) : schema.nullable(),
        ),

    place_of_work: yup
        .string()
        .when("activity", ([activity], schema) =>
            activity.includes("work") ? schema.required(errors.required) : schema.nullable(),
        ),
    position: yup
        .string()
        .when("activity", ([activity], schema) =>
            activity.includes("work") ? schema.required(errors.required) : schema.nullable(),
        ),
};

export const transportSchema = {
    transport: yup
        .string()
        .oneOf(["car", "taxi", "transfer", "unknown"], errors.oneof)
        .when("tariff", ([tariff], schema) => {
            if (TARIFF_WITH_REQUIRED_TRANSPORT.includes(tariff)) {
                return schema.required(errors.required);
            }
            return schema;
        }),
    car_brand: yup.string().when("transport", ([transport], schema) => {
        return transport === "car" ? schema.required(errors.required) : schema;
    }),
    car_state_number: yup.string().when("transport", ([transport], schema) => {
        return transport === "car" ? schema.required(errors.required) : schema;
    }),
};

export const locationSchema = {
    country_id: yup.number().required(errors.required).positive().integer(),
    city: yup.string().required(errors.required),
};

export const AccommodationSchema = {
    is_planning_to_stay_at_the_hotel: yup
        .boolean()
        .when("format", ([format], schema) => (format === "offline" ? schema.required(errors.required) : schema)),
};

export const phoneScheme = {
    phone: yup.string().required(errors.required),
};

export const legalScheme = {
    legal_type: yup.string().oneOf(LEGAL_TYPES).required(errors.required),
};

export const promocodeScheme = {
    promo_code_id: yup.number().required(errors.required).positive().integer(),
};

export const signUpOnlineSchema = yup.object({
    ...passwordsScheme,
    ...basicSchema,
    // disabled
    // ...locationSchema,
    // ...activitySchema,
});

export const signUpFromSberSchema = yup.object({
    ...passwordsScheme,
    ...basicSchema,
    ...phoneScheme,
    ...locationSchema,
    ...activitySchema,
});

export const signUpOnCampusSchema = yup.object({
    ...passwordsScheme,
    ...basicSchema,
    ...phoneScheme,
    ...legalScheme,
    ...locationSchema,
    ...activitySchema,
    ...transportSchema,
    ...AccommodationSchema,
});

export const signUpVipSchema = yup.object({
    ...promocodeScheme,
    ...passwordsScheme,
    ...basicSchema,
    ...locationSchema,
    ...activitySchema,
    ...transportSchema,
    ...AccommodationSchema,
});

export const signUpFromEducationSchema = yup.object({
    ...promocodeScheme,
    ...passwordsScheme,
    ...legalScheme,
    ...basicSchema,
    ...locationSchema,
    ...activitySchema,
    ...transportSchema,
    ...AccommodationSchema,
});

export const promoCodeSchema = yup.object({
    promoCode: yup.string().required(errors.required),
});
