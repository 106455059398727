import {
    ABOUT_CONFERENCE_HASH_ID,
    ABOUT_CONTEST_HASH_ID,
    AWARDS_HASH_ID,
    FAQ_HASH_ID,
    JURY_HASH_ID,
    NOIMATIONS_HASH_ID,
    PARTICIPATES_HASH_ID,
    PARTNERS_HASH_ID,
    PROGRAM_HASH_ID,
    SPEAKERS_HASH_ID,
    STAGES_HASH_ID,
    TARIFFS_HASH_ID,
} from "@/constants";
import { CONF_POLICY_PDF, OFFER_PDF } from "@/constants/links";

export const useFooter = () => {
    const DOC_LINKS = [
        {
            text: "Политика конфиденциальности",
            link: CONF_POLICY_PDF,
        },
        {
            text: "Оферта",
            link: OFFER_PDF,
        },
    ];

    const CONFERENCE_LINKS = [
        {
            text: "О конференции",
            link: ABOUT_CONFERENCE_HASH_ID,
        },
        {
            text: "Программа",
            link: PROGRAM_HASH_ID,
        },
        {
            text: "Формат участия",
            link: TARIFFS_HASH_ID,
        },
        {
            text: "Спикеры",
            link: SPEAKERS_HASH_ID,
        },
        {
            text: "FAQ",
            link: FAQ_HASH_ID,
        },
        {
            text: "Партнеры",
            link: PARTNERS_HASH_ID,
        },
    ];

    const CONTEST_LINKS = [
        {
            text: "О конкурсе",
            link: ABOUT_CONTEST_HASH_ID,
        },
        {
            text: "Кто участвует",
            link: PARTICIPATES_HASH_ID,
        },
        {
            text: "Призы победителям",
            link: AWARDS_HASH_ID,
        },
        {
            text: "Номинации",
            link: NOIMATIONS_HASH_ID,
        },
        {
            text: "Жюри",
            link: JURY_HASH_ID,
        },
        {
            text: "Этапы конкурса",
            link: STAGES_HASH_ID,
        },
    ];

    return {
        DOC_LINKS,
        CONFERENCE_LINKS,
        CONTEST_LINKS,
    };
};
