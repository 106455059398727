import React, { useContext } from "react";
import ProgramContainer from "../common/ProgramContainer";
import ProgramSlider from "../common/ProgramSlider";
import { ScheduleContext } from "@/context/ScheduleContext";
import Loader from "@/components/Loader";
import SessionContainer from "@/components/SessionCard/SessionContainer";
import ProgramIcons from "../ProgramIcons";

const OnlineProgram: React.FC = () => {
    const { onlineProgram } = useContext(ScheduleContext);

    if (onlineProgram.length === 0 || !onlineProgram) {
        return <Loader />
    }

    return (
        <div className="OnlineProgram">
            {/* opening  */}
            <SessionContainer title={onlineProgram[0].TimeString} icon={<ProgramIcons type="start"/>}>
                <ProgramContainer
                    data={onlineProgram[0]}
                    speakersTitle="Ведущие"
                />
            </SessionContainer>

            {/* discussion  */}
            <SessionContainer title={onlineProgram[1].TimeString}>
                <ProgramContainer
                    data={onlineProgram[1]}
                    mainStage
                    withLink
                />
            </SessionContainer>

            {/* coffee_break  */}
            <SessionContainer title={onlineProgram[2].TimeString} icon={<ProgramIcons type="break"/>}>
                <ProgramContainer
                    data={onlineProgram[2]}
                    cardColor="gray"
                    speakersTitle="Ведущие"
                />
            </SessionContainer>

            {/* multiple_sessions 01  */}
            <SessionContainer title={onlineProgram[3].TimeString}>
                <div className="OnCampusProgram__Multiple_sessions">
                    <ProgramSlider swiperId="online_multiple_sessions_01" data={[onlineProgram[3], onlineProgram[4], onlineProgram[5]]} />
                </div>
            </SessionContainer>

            {/* coffee_break  */}
            <SessionContainer title={onlineProgram[6].TimeString} icon={<ProgramIcons type="break"/>}>
                <ProgramContainer
                    data={onlineProgram[6]}
                    cardColor="gray"
                    speakersTitle="Ведущие"
                />
            </SessionContainer>

            {/* multiple_sessions 02  */}
            <SessionContainer title={onlineProgram[7].TimeString}>
                <div className="OnCampusProgram__Multiple_sessions">
                    <ProgramSlider swiperId="online_multiple_sessions_02" data={[onlineProgram[7], onlineProgram[8], onlineProgram[9]]} />
                </div>
            </SessionContainer>

            {/* coffee_break  */}
            <SessionContainer title={onlineProgram[10].TimeString} icon={<ProgramIcons type="break"/>}>
                <ProgramContainer
                    data={onlineProgram[10]}
                    cardColor="gray"
                    speakersTitle="Ведущие"
                />
            </SessionContainer>

            {/* multiple_sessions 03  */}
            <SessionContainer title={onlineProgram[11].TimeString}>
                <div className="OnCampusProgram__Multiple_sessions">
                    <ProgramSlider swiperId="online_multiple_sessions_03" data={[onlineProgram[11], onlineProgram[12], onlineProgram[13]]} />
                </div>
            </SessionContainer>

            {/* finish  */}
            <SessionContainer title={onlineProgram[14].TimeString} icon={<ProgramIcons type="finish"/>}>
                <ProgramContainer
                    data={onlineProgram[14]}
                    bgColor="green"
                    cardColor="none"
                    speakersTitle="Ведущие"
                />
            </SessionContainer>
        </div>
    )
}

export default OnlineProgram;