import CompetitionHero from "./CompetitionHero";
import CompetitionFocus from "./CompetitionFocus";
import CompetitionStages from "./CompetitionStages";
import CompetitionJury from "./CompetitionJury";
import ShareBlock from "@/components/ShareBlock";
import CompetitionFAQ from "./CompetitionFAQ";
import Divider from "@/components/Divider";
import './index.scss';

const Competition: React.FC = () => {
    return (
        <main className="Competition">
            <div className="Competition__Box">
                <div>
                    <CompetitionHero />
                    <Divider />
                    <CompetitionFocus />
                </div>
                <CompetitionFAQ />
                <CompetitionJury />
                <CompetitionStages />
            </div>
            <ShareBlock />
        </main>
    )
}

export default Competition;