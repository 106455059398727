import React, { useMemo } from "react";
import { useFormik } from "formik";

import { toastNotifications } from "@/components/Toastify";
import TitlePageAdmin from "@/components/TitlePageAdmin";
import { profileSchema } from "@/Schema/profileSchema";
import { FormInput, PhoneInput } from "@/components/Inputs";
import Typography from "@/components/Typography";
import Button from "@/components/Button";
import { Tooltip } from "@/components/Tooltip";
import { useUser } from "@/context/UserContext";
import { hasError } from "@/utils/formik";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { ActivityType, ActivityTypeEnum } from "@/types/registration";
import { AvatarInput } from "@/components/AvatarInput";
import { updateUserInformations } from "@/api";
import InputSelectCountries from "@/components/InputSelectCountries";
import InputSelectCity from "@/components/InputSelectCity";
import { useFormikDebounceEmail } from "@/hooks/useFormikDebounceEmail";
import { useActivityControl } from "@/pages/SignUp/registrations";
import {
    TARIFF_WITH_REQUIRED_PHONE,
    TARIFF_WITH_REQUIRED_TRANSPORT,
} from "@/constants/validations";
import { ONLINE } from "@/constants/tariffs";
import Radio from "@/components/Radio";
import { ProfilePaymentInfo } from "../ProfilePaymentInfo";
import { Helmet } from "react-helmet";
import "./index.scss";

export const PersonalInformation: React.FC = () => {
    const { userInfo, setUserInfo } = useUser();

    const initialValues = useMemo(() => {
        const { name, surname, email, info } = userInfo ?? {};
        const {
            country_id,
            city,
            tariff,
            format,
            place_of_work,
            position,
            place_of_study,
            speciality,
            phone,
            transport,
            car_brand,
            car_state_number,
        } = info ?? {};
        return {
            tariff: tariff ?? "",
            format: format ?? "",
            name: name ?? "",
            surname: surname ?? "",
            avatar: null as File | null,
            email: email ?? "",
            phone: phone ?? "",
            country_id: country_id ?? "",
            city: city ?? "",
            place_of_work: place_of_work ?? "",
            position: position ?? "",
            place_of_study: place_of_study ?? "",
            speciality: speciality ?? "",
            transport: transport ?? "",
            car_brand: car_brand ?? "",
            car_state_number: car_state_number ?? "",
            activity: [
                ...(place_of_work ? ["work"] : []),
                ...(place_of_study ? ["study"] : []),
                ...(!place_of_work && !place_of_study ? ["no_activity"] : []),
            ] as Array<ActivityType>,
        };
    }, [userInfo]);

    const validationSchema = useMemo(() => {
        return profileSchema(initialValues);
    }, [initialValues]);

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnMount: true,
        validationSchema,
        onSubmit: async (formValues) => {
            try {
                const { activity, ...restFormValues } = formValues;
                const newUserData =
                    await updateUserInformations(restFormValues);
                setUserInfo(newUserData);
                toastNotifications.success("Изменения сохранены");
            } catch (e) {
                toastNotifications.error(`Ошибка!`);
            }
        },
    });

    useActivityControl(formik);

    const [email, setEmail] = useFormikDebounceEmail(formik);

    const isCheckWork = formik.values.activity.includes(ActivityTypeEnum.Work);
    const isCheckStudy = formik.values.activity.includes(ActivityTypeEnum.Study);
    const iseStudyAndWork = formik.values.activity.includes(ActivityTypeEnum.Study) && formik.values.activity.includes(ActivityTypeEnum.Work);

    return (
        <>
            <Helmet>
                <title>Персональная информация | СберУниверситет</title>
                <meta name="description" content="Персональная информация" />
            </Helmet>
            <form
                className="PersonalInformation"
                autoComplete="off"
                onSubmit={formik.handleSubmit}
            >
                <fieldset
                    className="PersonalInformation__Fieldset"
                    disabled={formik.isSubmitting}
                >
                    <div className="PersonalInformation__FormWrapBlock PersonalInformation__FormWrapBlock--Head">
                        <TitlePageAdmin
                            textDesktop="Персональная информация"
                            textMobile="Личный кабинет"
                        />
                        <ProfilePaymentInfo />
                        <div className="PersonalInformation__FormRowBlock">
                            <AvatarInput
                                src={userInfo?.avatar_url}
                                userName={`${initialValues.surname} ${initialValues.name}`}
                                onChange={(file) => {
                                    formik.setFieldValue("avatar", file);
                                }}
                            />
                        </div>
                    </div>
                    <div className="PersonalInformation__FormWrapBlockGroup">
                        <div className="PersonalInformation__FormWrapBlock">
                            <Typography color="dark" size="md" weight="bold">
                                Имя и фамилия
                            </Typography>
                            <div className="PersonalInformation__InputsWrapper">
                                <FormikErrorWrap formik={formik} name="surname">
                                    <FormInput
                                        label="Имя и фамилия"
                                        name="surname"
                                        value={formik.values.surname}
                                        error={hasError(formik, "surname")}
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                    />
                                </FormikErrorWrap>
                                <FormikErrorWrap formik={formik} name="name">
                                    <FormInput
                                        className="PersonalInformation__Input PersonalInformation__Input--LastInput"
                                        name="name"
                                        value={formik.values.name}
                                        error={hasError(formik, "name")}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                    />
                                </FormikErrorWrap>
                            </div>
                        </div>
                        <div className="PersonalInformation__FormWrapBlock">
                            <Typography color="dark" size="md" weight="bold">
                                Электронная почта
                            </Typography>
                            <div className="PersonalInformation__InputsWrapper">
                                <FormikErrorWrap formik={formik} name="email">
                                    <FormInput
                                        name="email"
                                        value={email}
                                        disabled
                                        label="Email"
                                        error={hasError(formik, "email")}
                                        onChange={(e) =>
                                            setEmail(e.target.value)
                                        }
                                        onBlur={formik.handleBlur}
                                    />
                                </FormikErrorWrap>
                                {TARIFF_WITH_REQUIRED_PHONE.includes(
                                    formik.values.tariff as any,
                                ) && (
                                    <FormikErrorWrap
                                        formik={formik}
                                        name="phone"
                                    >
                                        <PhoneInput
                                            id="phone"
                                            name="phone"
                                            label="Телефон"
                                            placeholder="Введите номер телефона"
                                            value={formik.values.phone}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            isError={hasError(formik, "phone")}
                                        />
                                    </FormikErrorWrap>
                                )}
                            </div>
                        </div>
                        {formik.values.format !== ONLINE && (
                            <>
                                <div className="PersonalInformation__FormWrapBlock">
                                    <Typography color="dark" size="md" weight="bold">
                                        Местонахождение
                                    </Typography>
                                    <div className="PersonalInformation__InputsWrapper">
                                        <FormikErrorWrap
                                            formik={formik}
                                            name="country_id"
                                        >
                                            <InputSelectCountries
                                                id="country_id"
                                                name="country_id"
                                                placeholder="Введите свою страну"
                                                label="Страна и город"
                                                value={formik.values.country_id}
                                                error={hasError(formik, "country_id")}
                                                onSelect={(country_id) =>
                                                    formik.setFieldValue(
                                                        "country_id",
                                                        country_id,
                                                    )
                                                }
                                                onBlur={() =>
                                                    formik.setFieldTouched("country_id")
                                                }
                                            />
                                        </FormikErrorWrap>
                                        <FormikErrorWrap formik={formik} name="city">
                                            <InputSelectCity
                                                id="city"
                                                name="city"
                                                placeholder="Введите свой город"
                                                error={hasError(formik, "city")}
                                                value={formik.values.city}
                                                countryId={formik.values.country_id}
                                                onSelect={(city) =>
                                                    formik.setFieldValue("city", city)
                                                }
                                                onBlur={() =>
                                                    formik.setFieldTouched("city")
                                                }
                                            />
                                        </FormikErrorWrap>
                                    </div>
                                </div>
                                <div className="PersonalInformation__FormWrapBlock">
                                    <Typography color="dark" size="md" weight="bold">
                                        Род деятельность
                                    </Typography>
                                    <FormikErrorWrap formik={formik} name="activity">
                                        <div className="PersonalInformation__RadioWrapper">
                                            <Radio
                                                className="PersonalInformation__Radio"
                                                id="work"
                                                name="activity"
                                                text="Работаю"
                                                value="work"
                                                checked={isCheckWork && !iseStudyAndWork}
                                                onChange={() => formik.setFieldValue("activity", ["work"])}
                                                onBlur={formik.handleBlur}
                                            />
                                            <Radio
                                                className="PersonalInformation__Radio"
                                                id="study"
                                                name="activity"
                                                text="Учусь"
                                                value="study"
                                                checked={isCheckStudy && !iseStudyAndWork}
                                                onChange={() => formik.setFieldValue("activity", ["study"])}
                                                onBlur={formik.handleBlur}
                                            />
                                            <Radio
                                                className="PersonalInformation__Radio"
                                                id="educationAndCareer"
                                                name="activity"
                                                text="Работаю и учусь"
                                                value="education_and_career"
                                                checked={iseStudyAndWork}
                                                onChange={() => formik.setFieldValue("activity", ["work", "study"])}
                                                onBlur={formik.handleBlur}
                                            />
                                            <Radio
                                                id="noactivity"
                                                name="activity"
                                                text="Не работаю и не учусь"
                                                value="no_activity"
                                                checked={formik.values.activity.includes("no_activity")}
                                                onChange={() => formik.setFieldValue("activity", ["no_activity"])}
                                                onBlur={formik.handleBlur}
                                            />
                                        </div>
                                    </FormikErrorWrap>

                                    <div className="PersonalInformation__InputsWrapper">
                                        {isCheckWork && (
                                            <div className="PersonalInformation__inputsGroup">
                                                <Typography
                                                    component="span"
                                                    size="xs"
                                                    color="gray"
                                                    weight="regular"
                                                >
                                                    Место работы и должность
                                                </Typography>
                                                <div className="PersonalInformation__inputsWrapper">
                                                    <FormikErrorWrap
                                                        formik={formik}
                                                        name="place_of_work"
                                                    >
                                                        <FormInput
                                                            id="place_of_work"
                                                            name="place_of_work"
                                                            placeholder="Ваше место работы"
                                                            value={
                                                                formik.values
                                                                    .place_of_work
                                                            }
                                                            error={hasError(
                                                                formik,
                                                                "place_of_work",
                                                            )}
                                                            onBlur={formik.handleBlur}
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                        />
                                                    </FormikErrorWrap>
                                                    <FormikErrorWrap
                                                        formik={formik}
                                                        name="position"
                                                    >
                                                        <FormInput
                                                            id="position"
                                                            name="position"
                                                            placeholder="Ваша должность"
                                                            value={
                                                                formik.values.position
                                                            }
                                                            error={hasError(
                                                                formik,
                                                                "position",
                                                            )}
                                                            onBlur={formik.handleBlur}
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                        />
                                                    </FormikErrorWrap>
                                                </div>
                                            </div>
                                        )}

                                        {isCheckStudy && (
                                            <div className="PersonalInformation__inputsGroup">
                                                <Typography
                                                    component="span"
                                                    size="xs"
                                                    color="gray"
                                                    weight="regular"
                                                >
                                                    Место учебы и специальнось
                                                </Typography>
                                                <div className="PersonalInformation__inputsWrapper">
                                                    <FormikErrorWrap
                                                        formik={formik}
                                                        name="place_of_study"
                                                    >
                                                        <FormInput
                                                            id="place_of_study"
                                                            name="place_of_study"
                                                            placeholder="Ваше место учебы"
                                                            value={
                                                                formik.values
                                                                    .place_of_study
                                                            }
                                                            error={hasError(
                                                                formik,
                                                                "place_of_study",
                                                            )}
                                                            onBlur={formik.handleBlur}
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                        />
                                                    </FormikErrorWrap>
                                                    <FormikErrorWrap
                                                        formik={formik}
                                                        name="speciality"
                                                    >
                                                        <FormInput
                                                            id="speciality"
                                                            name="speciality"
                                                            placeholder="Ваша специальность"
                                                            value={
                                                                formik.values.speciality
                                                            }
                                                            error={hasError(
                                                                formik,
                                                                "speciality",
                                                            )}
                                                            onBlur={formik.handleBlur}
                                                            onChange={
                                                                formik.handleChange
                                                            }
                                                        />
                                                    </FormikErrorWrap>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </>
                        )}
                        {TARIFF_WITH_REQUIRED_TRANSPORT.includes(
                            formik.values.tariff as any,
                        ) && (
                            <div className="PersonalInformation__FormWrapBlock">
                                <Typography
                                    color="dark"
                                    size="sm"
                                    weight="bold"
                                >
                                    Транспорт
                                </Typography>
                                <FormikErrorWrap
                                    formik={formik}
                                    name="transport"
                                >
                                    <div className="PersonalInformation__RadioWrapper">
                                        <Radio
                                            className="PersonalInformation__Radio"
                                            id="car"
                                            name="transport"
                                            text="Автомобиль"
                                            value="car"
                                            checked={
                                                formik.values.transport ===
                                                "car"
                                            }
                                            onChange={formik.handleChange}
                                        />
                                        <Radio
                                            className="PersonalInformation__Radio"
                                            id="taxi"
                                            name="transport"
                                            text="Такси"
                                            value="taxi"
                                            checked={
                                                formik.values.transport ===
                                                "taxi"
                                            }
                                            onChange={formik.handleChange}
                                        />
                                        <Radio
                                            className="PersonalInformation__Radio"
                                            id="transfer"
                                            name="transport"
                                            text="Трансфер"
                                            value="transfer"
                                            checked={
                                                formik.values.transport ===
                                                "transfer"
                                            }
                                            onChange={formik.handleChange}
                                        />
                                        <Radio
                                            className="PersonalInformation__Radio"
                                            id="unknown"
                                            name="transport"
                                            text="Пока не знаю"
                                            value="unknown"
                                            checked={
                                                formik.values.transport ===
                                                "unknown"
                                            }
                                            onChange={formik.handleChange}
                                        />
                                    </div>
                                </FormikErrorWrap>

                                {formik.values.transport === "car" && (
                                    <div className="PersonalInformation__inputsGroup">
                                        <Typography
                                            component="span"
                                            size="xs"
                                            color="gray"
                                            weight="regular"
                                        >
                                            Место учебы и специальнось
                                        </Typography>
                                        <div className="PersonalInformation__inputsWrapper">
                                            <FormikErrorWrap
                                                formik={formik}
                                                name="car_brand"
                                            >
                                                <FormInput
                                                    type="text"
                                                    id="car_brand"
                                                    name="car_brand"
                                                    placeholder="Введите марку автомобиля"
                                                    value={
                                                        formik.values.car_brand
                                                    }
                                                    error={hasError(
                                                        formik,
                                                        "car_brand",
                                                    )}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                            </FormikErrorWrap>
                                            <FormikErrorWrap
                                                formik={formik}
                                                name="car_state_number"
                                            >
                                                <FormInput
                                                    type="text"
                                                    id="car_state_number"
                                                    name="car_state_number"
                                                    placeholder="Гос номер автомобиля"
                                                    value={
                                                        formik.values
                                                            .car_state_number
                                                    }
                                                    error={hasError(
                                                        formik,
                                                        "car_state_number",
                                                    )}
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    onBlur={formik.handleBlur}
                                                />
                                            </FormikErrorWrap>
                                        </div>
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                    <Tooltip
                        className="PersonalInformation__Tooltip"
                        tooltip={
                            formik.dirty
                                ? "Заполните поля"
                                : "Внесите изменения"
                        }
                        disabled={formik.dirty && formik.isValid}
                    >
                        <Button
                            className="PersonalInformation__ButtonSave"
                            tag="button"
                            BtnType="submit"
                            gradient
                            type="colored"
                            size="lg"
                            TextSize="sm"
                            TextWeight="semiBold"
                            text="Сохранить изменения"
                            disabled={
                                !formik.dirty ||
                                !formik.isValid ||
                                formik.isSubmitting
                            }
                        />
                    </Tooltip>
                </fieldset>
            </form>
        </>
    );
};
