import React from "react";
import Container from "@/components/Container";
import Headline from "@/components/Headline";
import Typography from "@/components/Typography";
import "./index.scss";

const subText = "С 2016 года проводим ее для тех, кто вовлечен \nв корпоративное, бизнес, высшее и среднее образование";

const ConferenceInfo: React.FC = () => {
    return (
        <section className="ConferenceInfo">
            <Container>
                <div className="ConferenceInfo__Box">
                    <Typography className="ConferenceInfo__UpperText" size="md" weight="regular" color="light">
                        {subText}
                    </Typography>
                    <Headline className="ConferenceInfo__Title" level="h2" size="xlg" theme="light">
                        <div>Ждем вас на конференции</div>
                        <div>СберУниверситета</div>
                        <div className="ConferenceInfo__Title ConferenceInfo__Title--Gradiant">про обучение и&nbsp;развитие</div>
                    </Headline>
                </div>
            </Container>
        </section>
    );
};

export default ConferenceInfo;
