import classNames from "classnames";
import "./index.scss";

type Props = {
    className?: string;
};

const GlowBubbleBg: React.FC<Props> = ({ className }) => {
    return (
        <div className={classNames("GlowBubbleBg", className)}>
            <svg xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <filter id="goo">
                        <feGaussianBlur
                            in="SourceGraphic"
                            stdDeviation="10"
                            result="blur"
                        />
                        <feColorMatrix
                            in="blur"
                            mode="matrix"
                            values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
                            result="goo"
                        />
                        <feBlend in="SourceGraphic" in2="goo" />
                    </filter>
                </defs>
            </svg>
            <div className="GlowBubbleBg__Container">
                <div className="GlowBubbleBg__Gradiant1"></div>
                <div className="GlowBubbleBg__Gradiant2"></div>
                <div className="GlowBubbleBg__Gradiant3"></div>
            </div>
        </div>
    );
};

export default GlowBubbleBg;
