import React from "react";
import TariffHeader from "./TariffHeader";
import TariffContent from "./TariffContent";
import { TARIFFS_HASH_ID } from "@/constants";
import "./index.scss";

const ChooseTariff: React.FC = () => {
    return (
        <section className="ChooseTariff" id={TARIFFS_HASH_ID} data-scroll-block="end" data-scroll-behavior="instant">
            <TariffHeader />
            <TariffContent />
        </section>
    );
};

export default ChooseTariff;
