import React from "react";
import { Outlet } from "react-router-dom";
import AccessGateway from "@/components/AccessGateway";
import routes from "@/routes";
import { Helmet } from "react-helmet";

export const ChangeTariff: React.FC = () => {
    return (
        <>
            <Helmet>
                <title>Выбрать тариф | СберУниверситет</title>
                <meta name="description" content="Выбрать тариф " />
            </Helmet>
            <AccessGateway onCloseNavigateTo={routes.profileTariffs}>
                <Outlet />
            </AccessGateway>
        </>
    );
};
