import React, { useMemo } from "react";

import Button from "@/components/Button";
import { Tooltip } from "@/components/Tooltip";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { FormInput } from "@/components/Inputs";
import { hasError } from "@/utils/formik";
import { FormikData } from "@/types/formik";

import "./index.scss";
import Typography from "@/components/Typography";

const SberInformation = ({
    formik,
    nextStep,
}: {
    formik: FormikData<any>;
    nextStep: () => void;
}) => {
    const isValid = useMemo(() => {
        return !(
            hasError(formik, "place_of_work", false) ||
            hasError(formik, "position", false)
        );
    }, [formik]);

    return (
        <>
            <div className="SberInformation__inputs">
                <Typography
                    className="LocationInformation__Title"
                    size="xs"
                    weight="regular"
                    color="gray"
                >
                    Место работы и должность
                </Typography>
                <div className="SberInformation__InputsGroup">
                    <FormikErrorWrap formik={formik} name="place_of_work">
                        <FormInput
                            className="SberInformation__input"
                            type="text"
                            id="place_of_work"
                            name="place_of_work"
                            placeholder="Наименование вашей компании"
                            error={hasError(formik, "place_of_work")}
                            value={formik.values.place_of_work}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </FormikErrorWrap>

                    <FormikErrorWrap formik={formik} name="position">
                        <FormInput
                            className="SberInformation__input"
                            type="text"
                            id="position"
                            name="position"
                            placeholder="Ваша должность"
                            value={formik.values.position}
                            error={hasError(formik, "position")}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                        />
                    </FormikErrorWrap>
                </div>
            </div>

            <Tooltip
                className="SberInformation__Tooltip"
                tooltip={"Необходимо заполнить все поля"}
                disabled={isValid}
            >
                <Button
                    className="SberInformation__Btn"
                    tag="button"
                    onClick={nextStep}
                    text="Далее"
                    size="lg"
                    type="colored"
                    isWide
                    gradient
                    disabled={!isValid}
                />
            </Tooltip>
        </>
    );
};

export default SberInformation;
