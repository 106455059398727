import React from "react";
import { Accordion, AccordionItem } from "@szhsin/react-accordion"
import { ReactComponent as ArrowIcon} from './assets/arrow.svg';
import { FaqItemAccordion } from "../types";
import './index.scss'

type Props = {
    questions_and_answers: FaqItemAccordion[]
    showLastQuastion?: boolean
}

const FaqAccordion: React.FC<Props> = ({
    questions_and_answers,
    showLastQuastion = false
}) => {
    return (
        <Accordion className="FaqAccordion" transition transitionTimeout={500}>
            {
                questions_and_answers && questions_and_answers.map((item, index) => {
                    return (
                        <AccordionItem
                            className={"FaqAccordion__Item"}
                            header={
                                <>
                                    {item.question} <ArrowIcon className="FaqAccordion__Arrow" />
                                </>
                            }
                            key={index}
                            initialEntered={showLastQuastion && index === questions_and_answers.length - 1}
                        >
                            <div
                                className="FaqAccordion__ItemContent"
                                dangerouslySetInnerHTML={{
                                    __html: item.answer,
                                }}
                            />
                        </AccordionItem>
                    );
                })
            }
        </Accordion>
    );
};

export default FaqAccordion;
