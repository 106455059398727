import React, { useState } from "react";
import Container from "@/components/Container";
import VidoeSrc from "@/assets/icons/video-bg.mp4";
import Headline from "@/components/Headline";
import Typography from "@/components/Typography";
import UsersIcon from "./assets/ic_users.png";
import HandshakeIcon from "./assets/ic_handshake.png";
import CardIcon from "./assets/ic_user_card.png";
import FlashIcon from "./assets/flash_icon.png";
import VideoPoster from "./assets/video-poster.png";
import useMedia from "@/hooks/useMedia";
import SkeletonImg from "@/components/SkeletonImg";
import { ABOUT_CONFERENCE_HASH_ID } from "@/constants";
import "./index.scss";

const ConferenceStatistics: React.FC = () => {
    const [isCanPlay, setIsCanPlay] = useState<boolean>(false);
    const { isDesktop } = useMedia();

    const iconSize = isDesktop ? 140 : 112;

    const handleLoading = () => {
        setIsCanPlay(true);
    };

    return (
        <section className="ConferenceStatistics" id={ABOUT_CONFERENCE_HASH_ID}>
            <Container>
                <div className="ConferenceStatistics__Items">
                    <div className="ConferenceStatistics__ItemsWrapper ConferenceStatistics__ItemsWrapper--videoWrapper">
                        <figure className="ConferenceStatistics__VideoWrapper">
                            <SkeletonImg
                                src={VideoPoster}
                                isShowChildren={isCanPlay}
                                isInView={true}
                                alt="Интервью с гостями"
                            >
                                <video
                                    className="ConferenceStatistics__Video"
                                    poster={VideoPoster}
                                    preload="metadata"
                                    playsInline
                                    loop
                                    autoPlay
                                    muted
                                    onCanPlay={handleLoading}
                                >
                                    <source src={VidoeSrc} type="video/mp4" />
                                </video>
                            </SkeletonImg>
                            <figcaption className="ConferenceStatistics__VideoCaption">
                                <Headline
                                    className="ConferenceStatistics__VideoTitle"
                                    level="h3"
                                    size="sm"
                                    theme="light"
                                >
                                    Проводим <br />
                                    онлайн и очно
                                </Headline>
                                <Typography
                                    size="md"
                                    color="gray"
                                    weight="semiBold"
                                    isCentered
                                >
                                    в кампусе СберУниверситета
                                </Typography>
                            </figcaption>
                        </figure>
                        <div className="ConferenceStatistics__Item">
                            <img
                                src={FlashIcon}
                                alt="FlashIcon"
                                width={iconSize}
                                height={iconSize}
                            />
                            <div className="ConferenceStatistics__ItemText">
                                <Typography
                                    size="lg"
                                    color="light"
                                    weight="semiBold"
                                >
                                    &gt; 10
                                    <br />
                                    увлекательных активностей для&nbsp;гостей конференции
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="ConferenceStatistics__ItemsWrapper">
                        <div className="ConferenceStatistics__Item">
                            <img
                                src={UsersIcon}
                                alt="UsersIcon"
                                width={iconSize}
                                height={iconSize}
                            />
                            <div className="ConferenceStatistics__ItemText">
                                <Typography
                                    size="lg"
                                    color="light"
                                    weight="semiBold"
                                >
                                    &gt; 10 тысяч
                                    <br />
                                    участников
                                </Typography>
                                <Typography
                                    size="md"
                                    color="gray"
                                    weight="semiBold"
                                >
                                    Очно и онлайн <sup>*</sup>
                                </Typography>
                            </div>
                        </div>
                        <div className="ConferenceStatistics__Item">
                            <img
                                src={HandshakeIcon}
                                alt="Handshake Icon"
                                width={iconSize}
                                height={iconSize}
                            />
                            <div className="ConferenceStatistics__ItemText">
                                <Typography
                                    size="lg"
                                    color="light"
                                    weight="semiBold"
                                >
                                    &gt; 20 партнеров
                                    <br />
                                    конференции
                                </Typography>
                                <Typography
                                    size="md"
                                    color="gray"
                                    weight="semiBold"
                                >
                                    из&nbsp;бизнеса,&nbsp;образования<br/>и&nbsp;искусства
                                </Typography>
                            </div>
                        </div>
                        <div className="ConferenceStatistics__Item">
                            <img
                                src={CardIcon}
                                alt="CardIcon"
                                width={iconSize}
                                height={iconSize}
                            />
                            <div className="ConferenceStatistics__ItemText">
                                <Typography
                                    size="lg"
                                    color="light"
                                    weight="semiBold"
                                >
                                    &gt; 50 спикеров
                                </Typography>
                                <Typography
                                    size="md"
                                    color="gray"
                                    weight="semiBold"
                                >
                                    из бизнеса и индустрии
                                    <br />
                                    образования
                                </Typography>
                            </div>
                        </div>
                    </div>
                    <div className="ConferenceStatistics__SupText">
                        * На основе данных предыдущих лет
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default ConferenceStatistics;
