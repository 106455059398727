import React, { useState } from "react";
import Container from "@/components/Container";
import Typography from "@/components/Typography";
import Headline from "@/components/Headline";
import ExpertsImg from "./assets/experts.png";
import { ReactComponent as UsersIcon } from "./assets/users.svg";
import { ReactComponent as ClickIcon } from "./assets/click.svg";
import { ReactComponent as HandIcon } from "./assets/hand.svg";
import VideoPoster from "./assets/background-foruc-animation.jpg";
import useMedia from "@/hooks/useMedia";
import SkeletonImg from "@/components/SkeletonImg";
import VidoeSrc from "./assets/anim_sber-sphere.mp4";
import VidoeSrcMobile from "./assets/anim_sber-sphere_opt.mp4";
import { useInView } from "react-intersection-observer";
import { SPEAKERS_HASH_ID } from "@/constants";
import "./index.scss";
import scrollToSection from "@/utils/scrollToSection";

const Potential_List = [
    {
        text: "Как человеку понять\nсебя и что делать,\nчтобы сохранить свою идентичность  и оставаться актуальным в технологическую\nэпоху?",
        icon: <UsersIcon />,
    },
    {
        text: "Где искать смысл, поддержку и мотивацию в условиях постоянных изменений и неопределенности?",
        icon: <ClickIcon />,
    },
    {
        text: "Почему в эпоху технологий особенно важны сотворчество, сотрудничество и эмоциональный контакт с другими людьми?",
        icon: <HandIcon />,
    },
];

const ConferencePotential: React.FC = () => {
    const { isMobile } = useMedia();
    const [isCanPlay, setIsCanPlay] = useState<boolean>(false);
    const { ref, inView } = useInView({
        threshold: 0,
        triggerOnce: true,
        rootMargin: "300px",
    });

    const handleLoading = () => {
        setIsCanPlay(true);
    };

    const renderList = Potential_List.map((el, index) => (
        <div
            key={index}
            className={`ConferencePotential__ListItem ConferencePotential__ListItem--Gradient-${index + 1}`}
        >
            {el.icon}
            <Typography
                className="ConferencePotential__ListItemText"
                size="md"
                weight="semiBold"
                color="light"
            >
                {el.text}
            </Typography>
        </div>
    ));

    return (
        <section ref={ref} className="ConferencePotential">
            <Container className="ConferencePotential__Wrapper">
                <div className="ConferencePotential__VideoWrapper">
                    <SkeletonImg
                        src={VideoPoster}
                        isShowChildren={isCanPlay}
                        isInView={inView}
                        alt="animation sber sphere"
                    >
                        <video
                            className="ConferencePotential__Video"
                            poster={VideoPoster}
                            preload="metadata"
                            playsInline
                            loop
                            autoPlay
                            muted
                            onCanPlay={handleLoading}
                        >
                            <source
                                src={VidoeSrcMobile}
                                media="(max-width: 575px)"
                                type="video/mp4"
                            />
                            <source
                                src={VidoeSrc}
                                media="(min-width: 576px)"
                                type="video/mp4"
                            />
                        </video>
                    </SkeletonImg>
                </div>
                <div className="ConferencePotential__Header">
                    <Typography
                        className="ConferencePotential__PreTitle"
                        size="md"
                        weight="regular"
                        color="light"
                        isCentered
                    >
                        Фокус конференции
                    </Typography>
                    <Headline
                        className="ConferencePotential__Title"
                        level="h2"
                        size="xlg"
                        theme="light"
                        isCentered
                    >
                        Как раскрывать потенциал
                        <br />
                        человека в эпоху стремительного
                        <br />
                        развития технологий
                    </Headline>
                </div>
                <div className="ConferencePotential__List">{renderList}</div>
                <div className="ConferencePotential__ExpertsList">
                    <button
                        className="ConferencePotential__ExpertsItems"
                        onClick={() => scrollToSection(SPEAKERS_HASH_ID, 100, 'instant')}
                    >
                        <img src={ExpertsImg} alt="Experts" />
                    </button>
                    <div className="ConferencePotential__ExpertsInfo">
                        <Typography
                            className="ConferencePotential__ExpertsTitle"
                            size="lg"
                            weight="semiBold"
                            color="light"
                            isCentered
                        >
                            Вместе с лидерами Сбера и&nbsp;экспертами
                        </Typography>
                        <Typography
                            className="ConferencePotential__ExpertsText"
                            size="md"
                            weight="regular"
                            color="light"
                            isCentered
                        >
                            {isMobile
                                ? "образовательного сообщества\nобсудим, как развиваться\nчеловеку в гармонии с самим\nсобой, обществом\nи технологиями"
                                : "образовательного сообщества обсудим,\nкак развиваться человеку в гармонии с самим собой,\nобществом и технологиями"}
                        </Typography>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default ConferencePotential;
