import React, { PropsWithChildren } from "react";
import { BannerProvider } from "@/context/BannerContext";
import { NavProvider } from "@/context/NavContext";
import { ScheduleProvider } from "@/context/ScheduleContext";
import { UserProvider } from "@/context/UserContext";

const ContextProviders: React.FC<PropsWithChildren> = ({ children }) => {
    return (
        <UserProvider>
            <NavProvider>
                <BannerProvider>
                    <ScheduleProvider>{children}</ScheduleProvider>
                </BannerProvider>
            </NavProvider>
        </UserProvider>
    );
};

export default ContextProviders;
