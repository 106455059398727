import React from "react";
import { useFormik } from "formik";
import { isAxiosError } from "axios";
import PopupPasswordRecovery from "./PopupPasswordRecovery";
import { useToggle } from 'react-use';
import { toastNotifications } from "@/components/Toastify";
import { changePasswordSchema } from "@/Schema/changePasswordSchema";
import TitlePageAdmin from "@/components/TitlePageAdmin";
import { FormInput } from "@/components/Inputs";
import PasswordRules from "@/components/FormPasswordRules";
import { Tooltip } from "@/components/Tooltip";
import Button from "@/components/Button";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { hasError } from "@/utils/formik";
import { useUser } from "@/context/UserContext";
import { changeUserPassword } from "@/api";
import { ProfilePaymentInfo } from "@/pages/Profile/ProfilePaymentInfo";
import { Helmet } from "react-helmet";
import { MESSAGES } from "@/constants/messages";
import "./index.scss";

export const ChangePassword: React.FC = () => {
    const { userInfo } = useUser();
    const [isOpen, togglePopup] = useToggle(false);

    const formik = useFormik({
        initialValues: {
            old_password: "",
            new_password: "",
            new_password_confirmation: "",
        },
        validationSchema: changePasswordSchema,
        onSubmit: async (formValues, formikHelpers) => {
            try {
                await changeUserPassword(formValues);
                toastNotifications.success("Пароль успешно изменен");
                formikHelpers.resetForm();
                setTimeout(() => {
                    formikHelpers.setTouched({});
                }, 100);
            } catch (error) {
                if (isAxiosError(error) && error.response?.status === 422 && error.response.data.errors) {
                    formikHelpers.setErrors(error.response.data.errors);
                    toastNotifications.error(error.response.data.message);
                } else {
                    toastNotifications.error(MESSAGES.ERROR);
                }
            }
        },
    });

    return (
        <div className="ChangePassword">
            <Helmet>
                <title>Смена пароля | СберУниверситет</title>
                <meta name="description" content="Смена пароля" />
            </Helmet>
            <TitlePageAdmin textDesktop="Смена пароля" />
            <form className="ChangePassword__Form" autoComplete="off" onSubmit={formik.handleSubmit}>
                <div className="ChangePassword__WrapBlock">
                    <ProfilePaymentInfo className="ChangePassword__PaymentInfo"/>
                    <div className="ChangePassword__BlockInputs">
                        <FormikErrorWrap formik={formik} name="old_password">
                            <FormInput
                                className="ChangePassword__Input"
                                type="password"
                                name="old_password"
                                placeholder="Введите ваш пароль"
                                label="Ваш текущий пароль"
                                value={formik.values.old_password}
                                error={hasError(formik, "old_password")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </FormikErrorWrap>
                        <PasswordRules className="ChangePassword__RulesList">
                            <FormikErrorWrap formik={formik} name="new_password">
                                <FormInput
                                    className="ChangePassword__Input"
                                    type="password"
                                    name="new_password"
                                    placeholder="Введите новый пароль"
                                    label="Новый пароль"
                                    autoComplete="new_password"
                                    value={formik.values.new_password}
                                    error={hasError(formik, "new_password")}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                            </FormikErrorWrap>
                        </PasswordRules>
                        <FormikErrorWrap formik={formik} name="new_password_confirmation">
                            <FormInput
                                className="ChangePassword__Input"
                                type="password"
                                name="new_password_confirmation"
                                autoComplete="new_password"
                                placeholder="Новый пароль ещё  раз"
                                label="Повторите пароль, чтобы не ошибиться"
                                value={formik.values.new_password_confirmation}
                                error={hasError(formik, "new_password_confirmation")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                            />
                        </FormikErrorWrap>
                    </div>
                </div>
                <div className="ChangePassword__RowButtons">
                    <Tooltip
                        className="ChangePassword__Tooltip"
                        tooltip="Заполните все поля"
                        disabled={formik.isValid && formik.dirty}
                    >
                        <Button
                            className="ChangePassword__ButtonEdit"
                            tag="button"
                            BtnType="submit"
                            gradient
                            type="colored"
                            size="lg"
                            TextSize="sm"
                            TextWeight="semiBold"
                            text="Сохранить изменения"
                            disabled={!(formik.isValid && formik.dirty) || formik.isSubmitting}
                        />
                    </Tooltip>
                    <button className="ChangePassword__ButtonOpenModal" type="button" onClick={togglePopup}>
                        Не помню пароль
                    </button>
                </div>
                {/* {userInfo?.email && (
                )} */}
                <PopupPasswordRecovery isOpen={isOpen} userEmail={userInfo?.email ?? ''} onClose={togglePopup} />
            </form>
        </div>
    );
};
