import React from "react";
import BasicInformation from "../common/BasicInformation";
import SberInformation from "../common/SberInformation";
import LocationInformation from "../common/LocationInformation";
import CreatePassword from "../common/CreatePassword";
import ToSignIn from "../common/ToSignIn";
import AuthorizationFormHeader from "@/components/AuthorizationFormHeader";
import RegistrationPrevBtn from "@/components/RegistrationPrevBtn";
import FormStepper from "../common/FormStepper";
import { signUpFromSberSchema } from "@/Schema/signUpSchema";
import { toastNotifications } from "@/components/Toastify";
import FormBadge from "../common/FormBadge";
import useStepper from "@/hooks/useStepper";
import { useRegistrations } from "@/pages/SignUp/registrations";
import "./index.scss";

export const Steps = {
    BASE_INFO: 1,
    LOCATION_INFO: 2,
    SBER_INFO: 3,
    PASSWORD_INFO: 4,
};

export const STEPS_CHAIN = [Steps.BASE_INFO, Steps.LOCATION_INFO, Steps.SBER_INFO, Steps.PASSWORD_INFO];

export const formHeaderTitles = {
    [Steps.BASE_INFO]: "Создайте свой\nпрофиль для посещения\nконференции",
    [Steps.LOCATION_INFO]: "Укажите своё\nместонахождение",
    [Steps.SBER_INFO]: "Укажите своё место\nработы",
    [Steps.PASSWORD_INFO]: "Придумайте пароль\nдля вашего аккаунта",
};

const FromSber: React.FC = () => {
    const formik = useRegistrations({
        values: {
            format: "offline",
            tariff: "from_sber",
            activity: ["work"],
        },
        validationScheme: signUpFromSberSchema,
        onRegistered: async () => {
            window.ym?.(94122972,'reachGoal','form_offline_sber');
            toastNotifications.sberAccount();
        },
    });

    const { currStep, nextStep, prevStep } = useStepper(STEPS_CHAIN);
    const title = formHeaderTitles[currStep];
    const showSubtitle = Boolean(currStep === Steps.BASE_INFO);

    return (
        <>
            {currStep !== Steps.BASE_INFO && <RegistrationPrevBtn toPrev={prevStep} />}

            <FormBadge text="Я из Сбера" />

            <AuthorizationFormHeader
                className="FromSber__Header"
                title={title}
                subtitle={showSubtitle && "Добрый день!"}
                isPreTitle
            />

            <FormStepper className="FromSber__Stepper" steps={4} startFrom={1} currentStep={currStep} />

            <form className="FromSber__Form" autoComplete="off" onSubmit={formik.handleSubmit}>
                {currStep === Steps.BASE_INFO && (
                    <BasicInformation formik={formik} withPhoneNumber nextStep={nextStep} />
                )}

                {currStep === Steps.LOCATION_INFO && <LocationInformation formik={formik} nextStep={nextStep} />}

                {currStep === Steps.SBER_INFO && <SberInformation formik={formik} nextStep={nextStep} />}

                {currStep === Steps.PASSWORD_INFO && <CreatePassword formik={formik} />}
            </form>

            {currStep === Steps.BASE_INFO && <ToSignIn />}
        </>
    );
};

export default FromSber;
