'use client';
import React, { useCallback, useEffect, useImperativeHandle } from 'react';
import { FloatingArrow, FloatingPortal, useMergeRefs } from '@floating-ui/react';
import Typography from '@/components/Typography';
import { useTooltip } from './useTooltip';
import type { TooltipOptions, TooltipVariant } from './types';
import { ReactComponent as IconCloseSolid } from './assets/close-btn.svg';
import classNames from 'classnames';
import "./index.scss";

type FloatingPortalProps = Pick<React.ComponentProps<typeof FloatingPortal>, 'root'>;

export type TooltipProps = TooltipOptions &
    FloatingPortalProps & {
        className?: string;
        variant?: TooltipVariant;
        maxWidth?: string | number;
        withCloseButton?: boolean;
        tooltip: React.ReactNode | ((params: { close: () => void }) => React.ReactNode);
        tooltipPrepend?: React.ReactNode;
        tooltipAppend?: React.ReactNode;
        children: React.ReactElement;
        onOpen?: () => void;
        onClose?: () => void;
        onChangeShow?: (show: boolean) => void;
    };

export const Tooltip = React.forwardRef(
    (
        props: TooltipProps,
        ref: React.ForwardedRef<{
            close: () => void;
        }>,
    ) => {
        const {
            className,
            withCloseButton,
            variant = 'light',
            tooltip,
            tooltipPrepend,
            tooltipAppend,
            children,
            maxWidth,
            root,
            onOpen,
            onClose,
            onChangeShow,
            ...restProps
        } = props;

        const {
            isOpen,
            arrowRef,
            floating: { refs, context, floatingStyles },
            interactions: { getFloatingProps, getReferenceProps },
            setIsOpen,
        } = useTooltip(restProps);

        const mergedRef = useMergeRefs([refs.setReference, (children as any)?.ref]);

        const refProps = getReferenceProps({
            ref: mergedRef,
            ...children?.props,
        });

        useEffect(() => {
            if (isOpen) {
                onOpen?.();
            } else {
                onClose?.();
            }
            onChangeShow?.(isOpen);
        }, [isOpen, onOpen, onClose, onChangeShow]);

        const close = useCallback(() => {
            setIsOpen(false);
        }, []);

        useImperativeHandle(ref, () => {
            return {
                close,
            };
        });

        return (
            <>
                {React.cloneElement(children, refProps)}
                {isOpen && (
                    <FloatingPortal root={root}>
                        <div
                            ref={refs.setFloating}
                            className={classNames('TooltipPopper', {
                                [ `TooltipPopper--${variant}`]: variant
                            })}
                            style={{
                                ...floatingStyles,
                                maxWidth: maxWidth ?`${maxWidth}px` : "unset",
                            }}
                            data-testid="tooltip-floating"
                            {...getFloatingProps()}
                            >
                                {tooltipPrepend}
                                <Typography className='TooltipPopperContent' size='sm' weight='regular' color='dark'>
                                    {typeof tooltip === 'function' ? tooltip({ close }) : tooltip}
                                </Typography>
                                {tooltipAppend}
                                {withCloseButton && (
                                    <button
                                        className={classNames('TooltipPopperClosingBtn', {
                                            [ `TooltipPopperClosingBtn--${variant}`]: variant
                                        })}
                                        onClick={close}
                                    >
                                    <IconCloseSolid
                                            width={15}
                                            height={15}
                                        />
                                    </button>
                                )}
                                {restProps.arrow && (
                                    <FloatingArrow
                                        ref={arrowRef}
                                        className={classNames('TooltipArrow', {
                                            [ `TooltipArrow--${variant}`]: variant
                                        })}
                                        context={context}
                                    />
                                )}
                        </div>
                    </FloatingPortal>
                )}
            </>
        );
    },
);

Tooltip.displayName = 'Tooltip';
