import React, { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Header from "@/layouts/Header";
import Footer from "@/layouts/Footer";
import ToastifyContainer, { toastNotifications } from "@/components/Toastify";
import ScrollToTop from '@/utils/ScrollToTop';
import CookieNotificator from "@/components/CookieNotificator";
// import CallToActionBtn from "@/components/CallToActionBtn";
import CallToActionBanner from "@/layouts/CallToActionBanner";
import routes from "@/routes";
import { useIsAuthenticated } from "react-auth-kit";
import "./index.scss";

const excludedPaths = [
    routes.signin,
    routes.forgetPassword,
    routes.signup,
    routes.onlineSignup,
    routes.fromEducationSignup,
    routes.fromSberSignup,
    routes.onCampusSignup,
    routes.vipSignup,
    routes.profileChangeTariffCommon,
    routes.profileChangeTariffEducation,
    routes.profileChangeTariffSber,
];

const shouldDisplayHeaderFooter = (pathname: any) => {
    if (pathname.includes(`reset_password`)) {
        return false;
    }

    return !excludedPaths.includes(pathname);
};


const RootLayout: React.FC = () => {
    const { pathname, search } = useLocation();
    const navigate = useNavigate();
    const shouldDisplay = shouldDisplayHeaderFooter(pathname);
    const isAuth = useIsAuthenticated();

    useEffect(() => {
        const showPaymentStatus = () => {
            const searchParams = new URLSearchParams(search);
            const payment = searchParams.get("payment");
            const type = searchParams.get("type");

            if (payment && type) {
                switch (true) {
                    case payment === "success" && type === "legal":
                        toastNotifications.payment("accepted");
                        break;
                    case payment === "success" && type === "individual":
                        toastNotifications.payment("success");
                        break;
                    case payment === "fail":
                        toastNotifications.payment("failed");
                        break;
                    default:
                        break;
                }
                searchParams.delete("payment");
                searchParams.delete("type");
                navigate(
                    {
                        search: searchParams.toString(),
                    },
                    {
                        replace: true,
                    },
                );
            }
        };

        if (isAuth()) {
            showPaymentStatus();
        }
    }, [search, isAuth, navigate]);

    return (
        <ScrollToTop>
            <CallToActionBanner />
            {shouldDisplay && <Header />}
            <Outlet />
            {shouldDisplay && <Footer />}
            <ToastifyContainer />
            <div className="RootLayout__FixedElements">
                {/* closed in stage #2 */}
                {/* <CallToActionBtn /> */}
                <CookieNotificator />
            </div>
        </ScrollToTop>
    );
};

export default RootLayout;