import React from 'react';
import Container from '@/components/Container';
import './index.scss';

const Divider: React.FC = () => {
    return (
        <Container>
            <div className="Divider"></div>
        </Container>
    );
};

export default Divider;