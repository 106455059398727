import React, { useMemo } from "react";
import Radio from "@/components/Radio";
import { FormInput } from "@/components/Inputs";
import Button from "@/components/Button";
import { Tooltip } from "@/components/Tooltip";
import { hasError } from "@/utils/formik";
import { FormikErrorWrap } from "@/components/FormikErrorWrap";
import { FormikData } from "@/types/formik";
import Typography from "@/components/Typography";
import { ActivityTypeEnum } from "@/types/registration";
import "./index.scss";

const ActivityInformation = ({
    formik,
    nextStep,
}: {
    formik: FormikData<any>;
    nextStep: () => void;
}) => {
    const isValid = useMemo(() => {
        return !(
            hasError(formik, "activity", false) ||
            hasError(formik, "place_of_work", false) ||
            hasError(formik, "position", false) ||
            hasError(formik, "place_of_study", false) ||
            hasError(formik, "speciality", false)
        );
    }, [formik]);

    const isCheckWork = formik.values.activity.includes(ActivityTypeEnum.Work);
    const isCheckStudy = formik.values.activity.includes(ActivityTypeEnum.Study);
    const iseStudyAndWork = formik.values.activity.includes(ActivityTypeEnum.Study) && formik.values.activity.includes(ActivityTypeEnum.Work);

    return (
        <>
            <Typography className="ActivityInformation__text" size="sm" weight="semiBold" color="dark">
                Выберите один из вариантов
            </Typography>
            <div className="ActivityInformation__RadioWrapper">
                <Radio
                    className="ActivityInformation__Radio"
                    id="work"
                    name="activity"
                    text="Работаю"
                    value="work"
                    checked={isCheckWork && !iseStudyAndWork}
                    onChange={() => formik.setFieldValue("activity", ["work"])}
                    onBlur={formik.handleBlur}
                />
                <Radio
                    className="ActivityInformation__Radio"
                    id="study"
                    name="activity"
                    text="Учусь"
                    value="study"
                    checked={isCheckStudy && !iseStudyAndWork}
                    onChange={() => formik.setFieldValue("activity", ["study"])}
                    onBlur={formik.handleBlur}
                />
                <Radio
                    className="ActivityInformation__Radio"
                    id="educationAndCareer"
                    name="activity"
                    text="Работаю и учусь"
                    value="education_and_career"
                    checked={iseStudyAndWork}
                    onChange={() => formik.setFieldValue("activity", ["work", "study"])}
                    onBlur={formik.handleBlur}
                />
                <Radio
                    id="noactivity"
                    name="activity"
                    text="Не работаю и не учусь"
                    value="no_activity"
                    checked={formik.values.activity.includes("no_activity")}
                    onChange={() => formik.setFieldValue("activity", ["no_activity"])}
                    onBlur={formik.handleBlur}
                />
            </div>

            <div className="ActivityInformation__inputs">
                {isCheckWork && (
                    <div className="ActivityInformation__inputsGroup">
                        <Typography
                            component="span"
                            size="xs"
                            color="gray"
                            weight="regular"
                        >
                            Место работы и должность
                        </Typography>
                        <div className="ActivityInformation__inputsWrapper">
                            <FormikErrorWrap
                                formik={formik}
                                name="place_of_work"
                            >
                                <FormInput
                                    className="ActivityInformation__input"
                                    type="text"
                                    id="place_of_work"
                                    name="place_of_work"
                                    placeholder="Ваше место работы"
                                    value={formik.values.place_of_work}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={hasError(formik, "place_of_work")}
                                />
                            </FormikErrorWrap>

                            <FormikErrorWrap formik={formik} name="position">
                                <FormInput
                                    className="ActivityInformation__input"
                                    type="text"
                                    id="position"
                                    name="position"
                                    placeholder="Ваша должность"
                                    value={formik.values.position}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={hasError(formik, "position")}
                                />
                            </FormikErrorWrap>
                        </div>
                    </div>
                )}

                {isCheckStudy && (
                    <div className="ActivityInformation__inputsGroup">
                        <Typography
                            component="span"
                            size="xs"
                            color="gray"
                            weight="regular"
                        >
                            Место учебы и специальнось
                        </Typography>
                        <div className="ActivityInformation__inputsWrapper">
                            <FormikErrorWrap
                                formik={formik}
                                name="place_of_study"
                            >
                                <FormInput
                                    className="ActivityInformation__input"
                                    type="text"
                                    id="place_of_study"
                                    name="place_of_study"
                                    placeholder="Ваше место учебы"
                                    value={formik.values.place_of_study}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={hasError(formik, "place_of_study")}
                                />
                            </FormikErrorWrap>

                            <FormikErrorWrap formik={formik} name="speciality">
                                <FormInput
                                    className="ActivityInformation__input"
                                    type="text"
                                    id="speciality"
                                    name="speciality"
                                    placeholder="Ваша специальность"
                                    value={formik.values.speciality}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={hasError(formik, "speciality")}
                                />
                            </FormikErrorWrap>
                        </div>
                    </div>
                )}
            </div>
            <Tooltip
                className="ActivityInformation__Tooltip"
                tooltip={
                    hasError(formik, "activity", false)
                        ? "Необходимо выбрать один из вариантов"
                        : "Необходимо заполнить все поля"
                }
                disabled={isValid}
            >
                <Button
                    className="ActivityInformation__Btn"
                    tag="button"
                    onClick={nextStep}
                    text="Далее"
                    size="lg"
                    type="colored"
                    isWide
                    gradient
                    disabled={!isValid}
                />
            </Tooltip>
        </>
    );
};

export default ActivityInformation;
