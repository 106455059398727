import React, { useState } from "react";
import { Helmet } from "react-helmet";
import TariffsPlans from "./TariffsPlans";
import { TariffStatus } from "./TarrifStatus";
import { useUser } from "@/context/UserContext";
import { ProfilePaymentInfo } from "@/pages/Profile/ProfilePaymentInfo";
import { useCheckPaymentStatus } from "@/hooks/useCheckPaymentStatus";
import TitlePageAdmin from "@/components/TitlePageAdmin";
import EmojiSmile from "@/assets/icons/emoji-smile.png";
import "./index.scss";

const Tariffs: React.FC = () => {
    const { userInfo } = useUser();
    const [showWarn, setShowWarn] = useState(true);
    const { data: isNotPayedComplete } = useCheckPaymentStatus();
    const isLegalNotPayed =
        userInfo?.info.format === "offline" && userInfo?.info.legal_type === "legal" && isNotPayedComplete;
    return (
        <>
            <Helmet>
                <title>Выбранный тариф | СберУниверситет</title>
                <meta name="description" content="Выбранный тариф" />
            </Helmet>
            <div className="Tariffs">
                <div className="Tariffs__Header">
                    <TitlePageAdmin textDesktop="Выбранный тариф" />

                    <ProfilePaymentInfo />

                    {(userInfo?.status === "under_consideration" || isLegalNotPayed) && (
                        <TariffStatus className="Tariffs__Status" status="wait">
                            {userInfo.info.tariff === "common" && <>Ваша заявка на очное участие находится в обработке.</>}
                            {userInfo.info.tariff === "from_sber" && (
                                <>
                                    Ваша заявка на очное участие в формате <b>«Я из Сбера»</b> находится на рассмотрении.
                                </>
                            )}
                            {userInfo.info.tariff === "from_education" && (
                                <>
                                    Ваша заявка на очное участие в формате <b>«Я из образования»</b> находится на рассмотрении.
                                </>
                            )}
                            {isLegalNotPayed && (
                                <>
                                    <br />
                                    Пока ваша заявка на рассмотрении — вы будете записаны на онлайн-участие
                                    <br />
                                    Как только мы рассмотрим заявку — с вами свяжется менеджер для проведения оплаты.
                                    <br />
                                    После оплаты ваш тариф будет изменён
                                </>
                            )}
                        </TariffStatus>
                    )}

                    {showWarn && userInfo?.status === "refused" && (
                        <TariffStatus className="Tariffs__Status" status="reject">
                            <div className="Tariffs__StatusWarn">
                            <div className="Tariffs__StatusWarnContent">
                                    Ваша заявка на оформление очного тарифа была отклонена.
                                    <br />С причиной отклонения вы можете ознакомиться по почте<br />
                                    <b>{userInfo?.email}. Вы по-прежнему можете участвовать<br />в онлайн-формате</b>
                                    <img
                                        className="Tariffs__StatusEmoji"
                                        src={EmojiSmile}
                                        alt="emoji smile"
                                        width={14}
                                        height={14}
                                    />
                                </div>
                                <div className="Tariffs__StatusWarnAction">
                                    <button
                                        className="Tariffs__StatusWarnBtn"
                                        onClick={() => {
                                            setShowWarn(false);
                                        }}
                                    >
                                        Хорошо
                                    </button>
                                </div>
                            </div>
                        </TariffStatus>
                    )}
                </div>

                <TariffsPlans className="Tariffs__Plans" />
            </div>
        </>
    );
};

export default Tariffs;
