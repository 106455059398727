import React from "react";
import Countdown from "react-countdown";
import "./index.scss";

const DURATION_ATTEMPTS = 5000;

export type AttemptsTimeoutProps = {
    onComplete: () => void;
};

const AttemptsTimeout = ({ onComplete }: AttemptsTimeoutProps) => {
    return (
        <div className="AttemptsTimeout">
            <Countdown
                date={Date.now() + DURATION_ATTEMPTS}
                onComplete={onComplete}
                renderer={({ minutes, seconds }) => {
                    const formattedSeconds = seconds.toString().padStart(2, "0");
                    const formattedMinutes = minutes.toString().padStart(2, "0");
                    return (
                        <p className="AttemptsTimeout__Count-down">
                            Отправить повторно можно через {formattedMinutes}:{formattedSeconds}
                        </p>
                    );
                }}
            />
        </div>
    );
};

export default AttemptsTimeout;
