import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Typography from '@/components/Typography';
import BannerIcon from './assets/banner-icon.png';
import { AnimatePresence, motion } from 'framer-motion';
import BannerIconMobile from './assets/banner-icon-mobile.png';
import routes from '@/routes';
import useBanner from '@/hooks/useBanner';
import Marquee from "react-fast-marquee";
import './index.scss';

const BannerLine: React.FC = () => {
    return (
        <div className='BannerLine'>
            <picture>
                <source srcSet={BannerIconMobile} media="(max-width: 575px)" />
                <source srcSet={BannerIcon} media="(min-width: 576px)" />
                <img className="BannerLine__Icon" src={BannerIcon} alt="sber decoration" />
            </picture>
            <Typography className='BannerLine__Text' component='span' size='sm' weight='semiBold' color='light'>
                Примите участие в конкурсе EdTech-компаний до 30 сентября
            </Typography>
        </div>
    );
};

const CallToActionBanner: React.FC = () => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const { isBannerActive, setIsBannerActive } = useBanner();

    const handleClick = () => {
        navigate(routes.contest);
    };

    useEffect(() => {
        if (pathname === routes.conference) {
            setTimeout(() => {
                setIsBannerActive(true);
            }, 300);
        } else {
            setTimeout(() => {
                setIsBannerActive(false);
            }, 300);
        }
    }, [pathname]);

    return (
        <AnimatePresence>
            {isBannerActive && (
                <motion.div
                    initial={{ opacity: 0, translateY: -100 }}
                    animate={{ opacity: 1, translateY: 0 }}
                    exit={{ opacity: 0, translateY: -100 }}
                    transition={{ duration: 0.5 }}
                    onClick={handleClick}
                >
                    <section className="CallToActionBanner">
                        <Marquee autoFill>
                            <BannerLine />
                        </Marquee>
                    </section>
                </motion.div>
            )}
        </AnimatePresence>
    );
}
export default CallToActionBanner;