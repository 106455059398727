import React from "react";

import PromoCode from "../common/PromoCode";
import BasicInformation from "../common/BasicInformation";
import ActivityInformation from "../common/ActivityInformation";
import TransportInformation from "../common/TransportInformation";
import AccommodationChoice from "../common/AccommodationChoice";
import CreatePassword from "../common/CreatePassword";
import TicketPayment from "../common/TicketPayment";
import LocationInformation from "../common/LocationInformation";
import AuthorizationFormHeader from "@/components/AuthorizationFormHeader";
import RegistrationPrevBtn from "@/components/RegistrationPrevBtn";
import { signUpFromEducationSchema } from "@/Schema/signUpSchema";
import FormBadge from "../common/FormBadge";
import FormStepper from "../common/FormStepper";
import useStepper from "@/hooks/useStepper";
import { openPaymentAcquire, useRegistrations } from "@/pages/SignUp/registrations";
import { RequireNotAuth } from "@/components/RequireNotAuth";
import routes from "@/routes";
import { EducationWarn } from "./EducationWarn";
import { EducationAccomodationNote } from "./EducationAccomodationNote";
import { toastNotifications } from "@/components/Toastify";
import "./index.scss";
import ToSignIn from "../common/ToSignIn";

export const Steps = {
    PROMO_CODE: 0,
    BASE_INFO: 1,
    LOCATION_INFO: 2,
    ACTIVITY_INFO: 3,
    TRANSPORT_INFO: 4,
    ACCOMODATION_INFO: 5,
    PASSWORD_INFO: 6,
    PAYMENT_INFO: 7,
};

export const STEPS_CHAIN = [
    Steps.PROMO_CODE,
    Steps.BASE_INFO,
    Steps.LOCATION_INFO,
    Steps.ACTIVITY_INFO,
    Steps.TRANSPORT_INFO,
    Steps.ACCOMODATION_INFO,
    Steps.PASSWORD_INFO,
    Steps.PAYMENT_INFO,
];

export const formHeaderTitles = {
    [Steps.PROMO_CODE]: "Укажите свой промокод\nдля получения скидки",
    [Steps.BASE_INFO]: "Создайте свой\nпрофиль для посещения\nконференции",
    [Steps.LOCATION_INFO]: "Укажите своё\nместонахождение",
    [Steps.ACTIVITY_INFO]: "Укажите свой\nрод деятельности",
    [Steps.TRANSPORT_INFO]: "Как вы\nпланируете приехать\nна конференцию?",
    [Steps.ACCOMODATION_INFO]: "Планируете ли вы\nостаться в отеле\nСберУниверситета?",
    [Steps.PASSWORD_INFO]: "Придумайте пароль\nдля вашего аккаунта",
    [Steps.PAYMENT_INFO]: "Выберите тип оплаты\nбилета",
};

const FromEducation: React.FC = () => {
    const formik = useRegistrations({
        values: {
            format: "offline",
            tariff: "from_education",
        },
        validationScheme: signUpFromEducationSchema,
        onRegistered: async (values) => {
            window.ym?.(94122972,'reachGoal','form_offline_education');
            if (values.legal_type === "individual") {
                await openPaymentAcquire();
            } else {
                toastNotifications.payment("accepted");
            }
        },
    });

    const { currStep, nextStep, prevStep } = useStepper(STEPS_CHAIN);
    const title = formHeaderTitles[currStep];
    const showSubtitle = Boolean(currStep === Steps.PROMO_CODE || currStep === Steps.BASE_INFO);
    return (
        <>
            {(currStep !== Steps.PAYMENT_INFO || formik.values.legal_type === "legal") && (
                <RequireNotAuth redirectTo={routes.profile} />
            )}

            {currStep !== Steps.PROMO_CODE && currStep !== Steps.BASE_INFO && <RegistrationPrevBtn toPrev={prevStep} />}

            <FormBadge text="Я из образования" />

            <AuthorizationFormHeader
                className="FromEducation__Header"
                title={title}
                subtitle={showSubtitle && "Добрый день!"}
                isPreTitle
            />

            {currStep === Steps.PROMO_CODE && <EducationWarn />}

            {currStep === Steps.ACCOMODATION_INFO && <EducationAccomodationNote />}

            {currStep !== Steps.PAYMENT_INFO && <FormStepper steps={5} startFrom={1} currentStep={currStep} />}

            {currStep === Steps.PROMO_CODE ? (
                <PromoCode
                    tariff={formik.values.tariff}
                    onAcceptCode={(codeId) => {
                        formik.setFieldValue("promo_code_id", codeId);
                        nextStep();
                    }}
                />
            ) : (
                <form className="FromEducation__Form" autoComplete="off" onSubmit={formik.handleSubmit}>
                    {currStep === Steps.BASE_INFO && <BasicInformation formik={formik} nextStep={nextStep} />}

                    {currStep === Steps.LOCATION_INFO && <LocationInformation formik={formik} nextStep={nextStep} />}

                    {currStep === Steps.ACTIVITY_INFO && <ActivityInformation formik={formik} nextStep={nextStep} />}

                    {currStep === Steps.TRANSPORT_INFO && <TransportInformation formik={formik} nextStep={nextStep} />}

                    {currStep === Steps.ACCOMODATION_INFO && (
                        <AccommodationChoice formik={formik} nextStep={nextStep} />
                    )}

                    {currStep === Steps.PASSWORD_INFO && <CreatePassword formik={formik} nextStep={nextStep} />}

                    {currStep === Steps.PAYMENT_INFO && <TicketPayment formik={formik} discount />}
                </form>
            )}

            {currStep === Steps.BASE_INFO && <ToSignIn />}
        </>
    );
};

export default FromEducation;
