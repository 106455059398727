import React from "react";

import PromoCode from "../common/PromoCode";
import BasicInformation from "../common/BasicInformation";
import ActivityInformation from "../common/ActivityInformation";
import TransportInformation from "../common/TransportInformation";
import AccommodationChoice from "../common/AccommodationChoice";
import CreatePassword from "../common/CreatePassword";
import LocationInformation from "../common/LocationInformation";
import AuthorizationFormHeader from "@/components/AuthorizationFormHeader";
import RegistrationPrevBtn from "@/components/RegistrationPrevBtn";
import { signUpVipSchema } from "@/Schema/signUpSchema";
import { toastNotifications } from "@/components/Toastify";
import FormStepper from "../common/FormStepper";
import useStepper from "@/hooks/useStepper";
import { useRegistrations } from "@/pages/SignUp/registrations";
import FormBadge from "../common/FormBadge";
import { EducationAccomodationNote } from "../FromEducation/EducationAccomodationNote";
import useMedia from "@/hooks/useMedia";
import ToSignIn from "../common/ToSignIn";
import "./index.scss";

const Steps = {
    PROMO_CODE: 0,
    BASE_INFO: 1,
    LOCATION_INFO: 2,
    ACTIVITY_INFO: 3,
    TRANSPORT_INFO: 4,
    ACCOMODATION_INFO: 5,
    PASSWORD_INFO: 6,
};

const STEPS_CHAIN = [
    Steps.PROMO_CODE,
    Steps.BASE_INFO,
    Steps.LOCATION_INFO,
    Steps.ACTIVITY_INFO,
    Steps.TRANSPORT_INFO,
    Steps.ACCOMODATION_INFO,
    Steps.PASSWORD_INFO,
];

const formHeaderTitles = {
    [Steps.BASE_INFO]: "Зарегистрируйте свой\nVIP аккаунт",
    [Steps.LOCATION_INFO]: "Укажите своё\nместонахождение",
    [Steps.ACTIVITY_INFO]: "Укажите свой\nрод деятельности",
    [Steps.TRANSPORT_INFO]: "Как вы\nпланируете приехать\nна конференцию?",
    [Steps.ACCOMODATION_INFO]: "Планируете ли вы\nостаться в гостинице\nСберУниверситета?",
    [Steps.PASSWORD_INFO]: "Придумайте пароль\nдля вашего аккаунта",
};

const VipAccount = () => {
    const { isMobile } = useMedia();
    const formik = useRegistrations({
        values: {
            format: "offline",
            tariff: "vip",
        },
        validationScheme: signUpVipSchema,
        onRegistered: async () => {
            toastNotifications.success("Вы зарегистрированы на конференцию");
        },
    });

    const { currStep, nextStep, prevStep } = useStepper(STEPS_CHAIN);
    const title = formHeaderTitles[currStep];
    const showSubtitle = Boolean(currStep === Steps.PROMO_CODE || currStep === Steps.BASE_INFO);

    return (
        <>
            {currStep !== Steps.PROMO_CODE && currStep !== Steps.BASE_INFO && <RegistrationPrevBtn toPrev={prevStep} />}

            <FormBadge text="VIP-аккаунт" />

            {currStep === Steps.PROMO_CODE ? (
                <AuthorizationFormHeader
                    className="VipAccount__HeaderPromoCode"
                    title={"Введите код\nподтверждения,чтобы\nначать регистрацию\nVIP-аккаунта"}
                    subtitle={"Код был отправлен вместе с пригашением"}
                />
            ) : (
                <AuthorizationFormHeader
                    className="VipAccount__Header"
                    title={title}
                    subtitle={showSubtitle && "Добрый день!"}
                    isPreTitle
                />
            )}

            {currStep === Steps.ACCOMODATION_INFO && <EducationAccomodationNote />}

            <FormStepper steps={6} startFrom={1} currentStep={currStep} dividerSize={isMobile ? "14px" : "24px"} />
            {currStep === Steps.PROMO_CODE ? (
                <PromoCode
                    tariff={formik.values.tariff}
                    label="Код подтверждения"
                    onAcceptCode={(codeId) => {
                        formik.setFieldValue("promo_code_id", codeId);
                        nextStep();
                    }}
                />
            ) : (
                <form className="VipAccount__Form" autoComplete="off" onSubmit={formik.handleSubmit}>
                    {currStep === Steps.BASE_INFO && <BasicInformation formik={formik} nextStep={nextStep} />}

                    {currStep === Steps.LOCATION_INFO && <LocationInformation formik={formik} nextStep={nextStep} />}

                    {currStep === Steps.ACTIVITY_INFO && <ActivityInformation formik={formik} nextStep={nextStep} />}

                    {currStep === Steps.TRANSPORT_INFO && <TransportInformation formik={formik} nextStep={nextStep} />}

                    {currStep === Steps.ACCOMODATION_INFO && (
                        <AccommodationChoice formik={formik} nextStep={nextStep} />
                    )}

                    {currStep === Steps.PASSWORD_INFO && <CreatePassword formik={formik} />}
                </form>
            )}

            {currStep === Steps.BASE_INFO && <ToSignIn />}
        </>
    );
};

export default VipAccount;
