import React from 'react';
import TariffContainer from './TariffContainer';
import TariffAccommodationInfo from '@/components/TariffAccommodationInfo';
import { useUser } from "@/context/UserContext";
import './index.scss';

const freePlan = [
    {
        text: "Участие в интерактивных перерывах —\nбеседах с экспертами рынка"
    },
    {
        text: "Возможность переключаться\nмежду онлайн-трансляциями\nмероприятий"
    },
    {
        text: "Записи всех трансляций"
    },
]

const paidPlan = [
    {
        text: "Нетворкинг и погружение\nв профессиональное сообщество"
    },
    {
        text: "Посещение выставки EdTech-продуктов,\nзнакомство с сервисами Сбера и книжная\nвыставка-ярмарка от Библиотеки Сбера"
    },
    {
        text: "Обед и кофе-брейки"
    },
    {
        text: "Трансфер до и от кампуса, парковка"
    },
    {
        text: "Прогулки и экскурсии по кампусу\nСберУниверситета"
    },
    {
        text: "Пакет участника с подарками от сервисов\nСбера и СберУниверситета"
    },
]

const TariffContent: React.FC = () => {
    const { userInfo } = useUser();

    const isOnline = userInfo?.info.format === "online" ;
    const isOffline = userInfo?.info.format === "offline";
    const isVip = userInfo?.info.tariff === "vip";

    return (
        <div className='TariffContent__Wrapper'>
            <TariffContainer
                theme="dark"
                header="Очное участие"
                features={paidPlan}
                initValue
                currentPlan={isOffline}
                isDisabled={!!userInfo && !isOffline}
                tooltipText="Смена тарифа доступна в личном кабинете"
                vip={isVip}
            >
                <TariffAccommodationInfo />
            </TariffContainer>

            <TariffContainer
                theme="light"
                header="Онлайн-участие"
                features={freePlan}
                currentPlan={isOnline}
                isDisabled={!!userInfo && !isOnline}
                tooltipText={"Чтобы изменить тариф на «Онлайн», заполните\nформу в разделе «Вернуть денежные средства»"}
                free
            />
        </div>
    )
}

export default TariffContent;