import React from "react";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import AccessGateway from "@/components/AccessGateway";
import { Helmet } from "react-helmet";
import routes from "@/routes";

// Можно закрыть Route, когда заканчиваются места.
const CLOSE_SIGNUP_ROUTES: string[] = [
    // routes.fromSberSignup,
    // routes.fromEducationSignup,
    // routes.onCampusSignup,
];

const SignUp: React.FC = () => {
    const location = useLocation();

    if (CLOSE_SIGNUP_ROUTES.includes(location.pathname)) {
        return <Navigate to={routes.signupAnchor} state={{ from: location }} replace />;
    }

    return (
        <>
            <Helmet>
                <title>Создайте свой профиль | СберУниверситет</title>
                <meta name="description" content="Создайте свой профиль для посещения конференции" />
            </Helmet>
            <AccessGateway onCloseNavigateTo={routes.conference}>
                <Outlet />
            </AccessGateway>
        </>
    );
};

export default SignUp;
